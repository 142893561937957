import React, { useContext, useEffect, useState } from "react"; 
import { useHistory } from 'react-router-dom'; 
import { toast } from 'react-toastify';

import Input from 'components/Input'

import { 
    FormContent,
    FormTitle,
    FormText,
    FormLabel,
    FormTouch,
    FormSpacer,
    
    PlanContent,
    PlanImage,
    PlanText,

    SelectedPlan,
    SelectedPlanTitle,
    SelectedPlanText,

    FormWrap,
    LicenseText,

    TabsContent,
    TabItem,

    WhiteCard,
    CardBanner,
    CardTitle,
    CardText,
    ActionsRow

} from './styled'

import Button from "components/Button";
import SelectInstallment from "components/SelectInstallment";

import ContainerAuthenticated from "containers/Authenticated";

import { CoreContext } from "context/CoreContext";
import { ReadLimitProfile, ReadMultiScreen, ReadPremiumPlans } from "services/authentication"; 
import { normalizeStrapiList, normalizeStrapiRegister } from "utils";
import { CenterLoad, Load } from "ui/styled";

export default function DashboardLimit(){ 

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext)

    const [ screens, setScreens ] = useState([])
    const [ loading, setLoading ] = useState(false) 
    
    const init = async () => {
        setLoading(true)
        const result = await ReadMultiScreen(user?.id)

        if(result?.data?.length){
            const normalResult = normalizeStrapiList(result)
    
            const promises = normalResult.map(async item => {
                const profile = item?.profile_id ? await ReadLimitProfile(item?.profile_id) : null
                const stream_show = normalizeStrapiRegister(item?.stream_show)
                return {
                    ...item,
                    watching: stream_show?.name,
                    profile: profile?.name || "",
                    device: item.device
                }
            })
    
            const watchers = await Promise.all(promises)
            setScreens(watchers) 
        }

        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])
 
    return ( 
        <>   
            <ContainerAuthenticated primary>  
                <FormContent big>

                    <FormText white>Limite de telas</FormText> 
                    <PlanText>Muitas pessoas estão usando sua conta no momento. Para continuar assistindo troque de plano ou feche uma destas telas</PlanText>
                    
                    {
                        loading ? <CenterLoad> <Load /> </CenterLoad> 
                        : screens?.map((item, key) =>
                            <PlanText white key={key}> <b>{ item?.device } ({ item?.profile })</b> - { item?.watching } </PlanText>
                        )
                    }

                    <ActionsRow>
                        <Button outline onClick={() => navigate('dashboard')}>Cancelar</Button>
                        <Button primary onClick={() => history.goBack()}>Tentar novamente</Button>
                    </ActionsRow>

                    <FormSpacer /> 

                </FormContent>
            </ContainerAuthenticated> 
        </>
    );
}