import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'
  
export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;  

export const DashboardSpacer = styled.div.attrs({ 
})`             
    margin-top: 20px;
`;



export const PageSection = styled.div.attrs({ 
})`              
    padding: 20px 0 40px;
    background: var(--white-color);
`;

export const PageTitle = styled.div.attrs({ 
})`              
    font-size: 24px;
    font-weight: bold;
    color: var(--black-color);
    margin-bottom: 40px;
    
    text-align: center;
`;

export const PageText = styled.div.attrs({ 
})`    
    font-size: 16px;
    color: var(--black-color);
    margin-bottom: 20px;           
`;