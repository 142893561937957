import styled from 'styled-components'  
 
 



export const HeaderMenu = styled.div.attrs({ 
})`  
    font-size: 16px;
    font-weight: bold;
    color: var(--white-color);

    display: flex;
    align-items: center;
    gap: 0 12px;
    cursor: pointer;
    padding: 20px 0 20px;

`;
export const HeaderMenuImage = styled.div.attrs({ 
})`  
    width: 40px;
    height: 40px;

    border-radius: 20px;
    background: url(${ props => props.image }) no-repeat center center / cover ;
`;
export const HeaderMenuIcon = styled.img.attrs({ 
    src:'/icons/chevron.svg'
})`   
`;










export const HeaderMenuContent = styled.div.attrs({ 
})`   
    position: absolute;
    background: var(--white-color);

    font-family: 'PT Sans';
    font-weight: bold;
    font-size: 14px;
    color: var(--white-color);

    top: 72px;
    right: 10px;
    width: 240px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px var(--shadow-color);
`;
export const HeaderMenuContentSection = styled.div.attrs({ 
})`   
    background: var(--darkprimary-color);
    padding: 10px 20px;
    font-size: 14px; 
    ${
        props => props.white ? `
            color: var(--black-color);
            background: var(--white-color);
            border-radius: 0 0 10px 10px;
        ` : `
            border-radius: 10px 10px 0 0;
        `
    }
`;
export const HeaderMenuUser = styled.div.attrs({ 
})`   
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer; 

`;




export const HeaderMenuUserImage = styled.div.attrs({ 
})`   
    width: 40px;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;

    background: url(${ props => props.image }) no-repeat center center / cover;

    margin-right: 10px;
`;
export const HeaderMenuOption = styled.div.attrs({ 
})`   
    padding: 10px 0;
    cursor: pointer; 

    :hover{
        text-decoration: underline;
    }
`;



export const ContentAllowWithPassword = styled.div.attrs({ 
})`   
    position: fixed;
    inset: 0 0 0 0;
    background: var(--shadow-color);
    z-index: 20;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContentAllowWithPasswordContent = styled.div.attrs({ 
})`   
    padding: 20px;
    border-radius: 8px;
    background: var(--white-color);

    font-size: 18px;
`;

export const ContentAllowWithPasswordContentInput = styled.div.attrs({ 
})`   
    border: 1px solid var(--shadow-color);
    margin-top: 20px;
    min-width: 320px;
`;

export const ContentAllowWithPasswordContentActions = styled.div.attrs({ 
})`   
    display: flex;
    gap: 12px;
`;