import { CoreContext } from "context/CoreContext";
import React, { useContext, useEffect, useState } from "react";
import { SOCKET_ENDPOINT } from "services/api";

export default function useSocket(){

    const { user, currentProfile } = useContext(CoreContext)
    const [currentSocket, setCurrentSocket] = useState(null)

    const device = "Web"

    const emitPlaying = async (stream_show) => {
        if(currentSocket){
            currentSocket.emit("playing", { profile: currentProfile?.id, stream_show, user: user?.id, device })
        }
    }
    
    const emitStoping = async (stream_show) => {
        if(currentSocket){
            currentSocket.emit("stoping", { profile: currentProfile?.id, stream_show, user: user?.id, device })
        }
    }
    
    useEffect(() => {
        const socket = require("socket.io-client")(SOCKET_ENDPOINT, {
            transports: ["websocket"],
            reconnectionDelay: 10000,
            reconnectionDelayMax: 10000,
            withCredentials: true,
            extraHeaders: { }
        });

        setCurrentSocket(socket)

        socket.on("connect_error", (err) => {
            console.log(`connect_error due to ${err.message}`);
        });

        socket.on("connect", () => {
            // console.log("client connected")  
        });  

        return () => {
            socket.disconnect()
        }
    },[])
    
    return {
        emitPlaying,
        emitStoping
    }
}