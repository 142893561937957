import React, { useContext, useEffect, useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';
  

import { 
    
    BannerContent, 
    BannerCard, 
    BannerTitle, 
    ContentCardsPlan,
    ViewPreview,
    ContentActions, 

    RewviewDecoration,
    ReviewTitle,
    ReviewText,
    ReviewRate,
    ReviewRateIcon


} from "./styled"; 
import Button from "components/Button";
import { ReadPremiumPlans } from "services/authentication"; 
import { parsePlan } from "utils/parser";
import { CoreContext } from "context/CoreContext";
import { parseCurrency } from "utils";

export default function TalkingAboutUs(){

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { backgrounds } = useContext(CoreContext)

    const [plans, setPlans] = useState([])


    const init = async () => {
        const result = await ReadPremiumPlans()
        if(result?.plans?.length){
            setPlans(result.plans.map(item => parsePlan(item, backgrounds))) 
        }
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>  
            <BannerContent>
                <Container>
                    <Row>
                        <Col md={{ size: 12 }}> 
                            <BannerCard>

                                <BannerTitle> O que falam sobre nós </BannerTitle>
                                <ContentCardsPlan>


                                    {
                                        [0,1,2,3].map((item, key) => 
                                            <ViewPreview key={key}>
                                                <RewviewDecoration />
                                                <ReviewTitle>Olivier Santos</ReviewTitle>
                                                <ReviewText>
                                                    Muito bom, Amei...
                                                    Agora posso deixar meu filho assistindo
                                                </ReviewText>
                                                <ReviewRate>
                                                    {
                                                        [1,2,3,4,5].map((it, ky) => 
                                                            <ReviewRateIcon key={ky} />
                                                        )
                                                    }
                                                </ReviewRate>
                                            </ViewPreview> 
                                        )
                                    }

                                    
                                </ContentCardsPlan>
                                

                            </BannerCard>
                        </Col>
                    </Row>
                </Container>
            </BannerContent>
        </>
    );
}