import styled from 'styled-components' 

export const BannerImage = styled.div.attrs({ 
})`           

    position: relative;
    min-height: calc(100vh);
    width: 100%;

    background: var(--primary-color) url(/images/tv-background.png) no-repeat center center / cover;
`; 

export const BannerOut = styled.div.attrs({ 
})`            
    min-height: calc(100vh);
    height: 100%;
    width: 100%;

    background: var(--darkshadow-color); 
    mix-blend-mode: multiply;
    position: absolute;
    z-index:1;
`; 

const degradeeColor = "93,0,0"
// const degradeeColor = "249,94,20"

export const BannerContent = styled.div.attrs({ 
})`           
    min-height: calc(100vh);
    width: 100%; 

    background: -moz-linear-gradient(180deg, rgba(${ degradeeColor },.7) 0%, rgba(${ degradeeColor },.7) 110%);
    background: -webkit-linear-gradient(180deg, rgba(${ degradeeColor },.7) 0%, rgba(${ degradeeColor },.7) 110%);
    background: linear-gradient(180deg, rgba(${ degradeeColor },.7) 0%, rgba(${ degradeeColor },.7) 110%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(${ degradeeColor },.4)",endColorstr="rgba(${ degradeeColor },.4)",GradientType=1);

    position:relative;
    z-index:2;
`; 

export const BannerCard = styled.div.attrs({ 
})`        
    // max-width: 920px;
    margin: 0 auto;
    padding: 30px 0 0;
`; 



export const BannerTitle = styled.h1.attrs({ 
})`       
    font-size: 28px;
    color: var(--white-color);
    font-weight: bold;
    margin: 0 0 30px;
`;  

export const ContentCardsPlan = styled.div.attrs({ 
})`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: 30px;
`; 

export const ViewPreview = styled.div.attrs({ 
})`
    width: 260px;
    height: 330px;
    border-radius: 10px;
    background: var(--darkshadow-color) url(${ p => p.image }) no-repeat center center / cover; 

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`; 


export const ContentActions = styled.div.attrs({ 
})`
    max-width: 280px;
    margin: 0 auto;
    padding-top: 1px;
`; 







