import { GET, POST, PUT, DELETE } from './api'

export const UpdateMe = async (params) => {
    return await PUT(`/update/me`, params, true);
}

export const UpdatePassword = async (params) => {
    return await PUT(`/update/password`, params, true);
}

export const ReadMyHistory = async (profileId) => {
    return await GET(`/histories?profile=${ profileId }&populate[0]=stream_show.thumbnail&[limit]=8`, true);
}

export const AddHistory = async (profileId, params) => {
    return await POST(`/histories?profile=${ profileId }`, params, true);
}

export const RemoveHistory = async (profileId) => {
    return await DELETE(`/histories?profile=${ profileId }`, true);
}