import styled from 'styled-components'  
import { PrimaryColor } from 'ui/styled';
 
export const DashboardHeaderContainer = styled.div.attrs({ 
})`           
    height: 105px;
    width: 100%;

    background: linear-gradient(45deg, rgba(${PrimaryColor}, .45) 0%, rgba(${PrimaryColor}, .6) 25%, rgba(${PrimaryColor}, .75) 50%, rgba(${PrimaryColor}, .9) 75%, rgba(${PrimaryColor}, 1) 100%);
    background: var(--darkprimary-color);
    padding: 0 25px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 767px){
        flex-direction: column;
        height: initial;
    }
`;
 
export const DashboardHeaderSide = styled.div.attrs({ 
})`            
    display: flex;
    justify-content: flex-start;
    align-items: center; 

    position: relative;
`;
 
export const DashboardHeaderSideMobile = styled.div.attrs({ 
})`            
    display: flex;
    justify-content: flex-start;
    align-items: center; 

    @media(max-width: 399px){
        flex-direction: column; 
    }
`;

export const DashboardHeaderAction = styled.div.attrs({ 
})`           
    color: var(--white-color);
    font-size: 16px;
    cursor: pointer;

    ${
        props => props.active ? `
            font-weight: bold;
        ` : ``
    }

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }


    @media(max-width: 767px){
        padding: 0 10px;
    }

    @media(max-width: 399px){
        padding: 10px;
    }
`; 

export const DashboardHeaderActionIcon = styled.img.attrs({ 
    width: 60
})`            
    @media(max-width: 399px){
        margin-right: -20px;
    }
`; 