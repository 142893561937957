import styled from 'styled-components'  
 
export const FaqItem = styled.div.attrs({ 
})`
    ${
        props => props.landpage ? `
            background: var(--whiteshadow-color);
            width: 100%;
            padding: 16px 24px;
            border-radius: 16px;
        ` : ``
    }
`;
export const FaqItemAsk = styled.div.attrs({ 
})`            
    font-size: 18px;
    color: var(--black-color);
    ${
        props => props.landpage ? `
            color: var(--white-color); 
        ` : ``
    }
`;
export const FaqItemAnswer = styled.div.attrs({ 
})`
    font-size: 14px;
    color: var(--black-color); 
    ${
        props => props.landpage ? `
            color: var(--white-color); 
        ` : ``
    }
    padding: 10px 0;
`;
 

export const FaqItemHeader = styled.div.attrs({ 
})`
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    
    cursor: pointer;
`;
export const FaqItemIcon = styled.img.attrs(props => ({ 
    src: `/icons/chevron-${ props.landpage ? 'white' : 'black' }.svg`
}))`
    ${
        props => props.open ? `
            transform: rotate(180deg);
        ` : ``
    }
`;