import React, { useContext, useEffect, useState } from "react";  

import {    
    DashboardSpacer,

    PageTitle,
    PageSubtitle,
    PageText,
    PageSection

} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";  

import { CoreContext } from "context/CoreContext";
import { Container, Row, Col } from "reactstrap";
import { ReadAbout } from "services/authentication";
import { Load } from "ui/styled";

import {marked} from 'marked';
import ContainerLandpage from "containers/Landpage";
import Footer from "components/Landpage/Footer";
import Banner from "components/Landpage/Banner";

export default function DashboardRequestAccountTerminate(){   

    const { currentCategory } = useContext(CoreContext)

    const [loading, setLoading] = useState(false)
    const [about, setAbout] = useState("")

    const init = async () => {
        setLoading(true)
        const result =  await ReadAbout()
 
        if(result?.about){

            marked.setOptions({
                gfm: true,
                tables: true,
                breaks: false,
                pedantic: false,
                sanitize: true,
                smartLists: true,
                smartypants: false
              });

            setAbout(marked.parse(result?.about))
        }
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
            <ContainerLandpage> 
                <Banner onlyHeader />  
                <PageSection>
                    <Container>
                        <Row>
                            <Col md={{ size: 10, offset:1 }}>
                                <DashboardSpacer />
                                <PageTitle>Exclusão de Conta no HoxxTV</PageTitle> 
                               
                               
                                <PageText>
                                    Para encerrar sua conta no HoxxTV, fornecemos um processo simples e eficiente para realizar essa exclusão. Siga as etapas abaixo para solicitar a exclusão da sua conta. Lembre-se de que, após a exclusão, todos os seus dados serão removidos permanentemente e não serão recuperáveis.
                                </PageText>
                                
                                <PageText>
                                    Antes de iniciar o processo de exclusão, certifique-se de que você não possui nenhuma transação pendente, valores em conta ou qualquer atividade relevante que necessite de sua atenção. A exclusão da conta resultará na perda irreversível de todas as informações associadas a ela.
                                </PageText>
                                
                                <PageText>
                                    <b>Enviando o Pedido de Exclusão</b>
                                </PageText>
                                
                                <PageText>
                                    Envie um e-mail para <b>hoxxtv@gmail.com</b> a partir do endereço de e-mail vinculado à sua conta no HoxxTV. No corpo do e-mail, inclua as seguintes informações:
                                </PageText>

                                <PageText>
	                                <b>•</b>	Assunto do e-mail: Pedido de Exclusão de Conta
                                </PageText>

                                <PageText>
	                                <b>•</b>	Seu nome completo
                                </PageText>
                             
                                <PageText>
	                                <b>•</b>	Seu CPF
                                </PageText>

                                <PageText>
	                                <b>•</b>	Email da conta HoxxTV a ser excluída
                                </PageText>

                                <PageText>
	                                <b>•</b>	Razão pela qual você deseja excluir sua conta (opcional, mas apreciado para melhorar nossos serviços)
                                </PageText>

                                <PageText>
                                    <b>Confirmação da Solicitação</b>
                                </PageText>

                                <PageText>
                                    Você receberá uma confirmação por e-mail assim que sua solicitação for recebida. Certifique-se de verificar sua caixa de entrada e/ou pasta de spam para garantir que você tenha recebido a confirmação.
                                </PageText>

                                <PageText>
                                    <b>Processamento da Solicitação</b>
                                </PageText>

                                <PageText>
                                    Nossa equipe dedicada processará seu pedido de exclusão em até 48 horas úteis. Durante esse período, você ainda terá acesso à sua conta, mas ela será marcada para exclusão.
                                </PageText>

                                <PageText>
                                    <b>Confirmação da Exclusão</b>
                                </PageText>

                                <PageText>
                                    Assim que a exclusão for concluída, você receberá uma confirmação por e-mail. Certifique-se de verificar sua caixa de entrada para garantir que você tenha recebido a confirmação.
                                </PageText>

                                <PageText>
                                    Agradecemos por escolher o HoxxTV, e lamentamos ver você partir. Se houver alguma dúvida ou problema durante o processo, entre em contato conosco pelo mesmo e-mail de suporte mencionado anteriormente.
                                </PageText>




                            </Col>
                        </Row>
                    </Container>
                </PageSection>
                <Footer />
            </ContainerLandpage>
        </>
    );

}