import React, { useContext, useEffect, useRef, useState } from "react";  

import {     

    VideoTouch,
    
    ContainerVideoPlayer,
    ContainerVideo,


    VideoHeader,
    VideoHeaderSection,
    VideoHeaderBack,
    VideoHeaderImage,
    VideoHeaderContent,
    VideoHeaderSubtitle,
    VideoHeaderTitle,
    VideoHeaderAction,
    VideoHeaderActionIcon,

    VideoCenterContent,
    VideoCenterAction,
    VideoCenterPlay,
    VideoCenterPlayIcon,
 

    VideoBottomContent,
    VideoBottomAction,
    VideoBottomActionIcon,
    VideoBottomWrapper,
    VideoBottomVideo

} from "./styled";
 
import { CoreContext } from "context/CoreContext"; 

import ContainerProfile from "containers/Profile";
import { Container } from "reactstrap";
import DashboardPlayerProgress from "components/Dashboard/PlayerProgress";
import { useHistory, useParams } from "react-router-dom";
import { Load } from "ui/styled";
import { parseVideo, parseVideoShow } from "utils/parser";
import { ReadShowDetails, ReadPreviewsShow, ReadProgressPlayer, CreateProgressPlayer, UpdateProgressPlayer } from "services/catalog";
import { AddHistory } from "services/account";

import ReactHlsPlayer from 'react-hls-player';
import { exposeStrapiError, parseStrapiImage } from "utils";
import useSocket from "hooks/useSocket";

export default function DashboardPlayer(){   
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { video_id, running } = useParams()
    const { user, currentProfile } = useContext(CoreContext)

    const videoRef = useRef()
    const [playing, setPlaying] = useState(false)
    const [videoUrl, setVideoUrl] = useState('')
    const [previewMode, setPreviewMode] = useState(false)

    const { emitPlaying, emitStoping } = useSocket()

    const playPause = () => { 
        if( videoUrl && typeof videoRef?.current?.play === 'function' ){
            videoRef.current.play()
        }
    }

    const togglePreview = () => {
        setPreviewMode(!previewMode)
    }  

    useEffect(() => {
        if(videoUrl && running){ setTimeout(playPause, 100) ;}
    }, [videoUrl, running]);

    
    const [loading, setLoading] = useState(false)
    const [relateds, setRelateds] = useState([])
    const [details, setDetails] = useState({})

    const [nextStream, setNextStream] = useState(null)
    const [prevStream, setPrevStream] = useState(null)

    const loadVideo = async () => {
        setLoading(true)
        const result = await ReadShowDetails(video_id)
        const prevShow = await ReadPreviewsShow(video_id)

        console.log("result", result)
        console.log("prevShow", prevShow)

        const progressPlayer = await ReadProgressPlayer(video_id, currentProfile?.id)
        if(progressPlayer?.data?.length){
            const cProg = progressPlayer?.data
                ?.sort((a, b) => new Date(b?.attributes?.updatedAt).getTime() - new Date(a?.attributes?.updatedAt).getTime()  )
                    ?.filter(f => !f?.attributes?.data?.finishing)?.[0]
            setCurrentProgressPlayer(cProg)
            if(!cProg?.attributes?.data?.finishing){
                const progress = cProg?.attributes?.progress
                console.log("RECOVERED progress", cProg)
                setSeek(progress)
            }
        }

        if(!exposeStrapiError(result) && result?.related_shows?.length){
            setRelateds(result.related_shows?.map(parseVideo))
        }

        if(result?.stream_master_file?.url){
            setVideoUrl(`${ result?.stream_master_file?.url }`)
            console.log( 'CURRENT', `${ result?.stream_master_file?.url }`)
        }else{  
            if(result.limit){
                navigate('dashboard/limit')
            }else{
                navigate('dashboard/sign')
            }
        } 

        if(result.id){ setDetails(parseVideoShow(result)) }

        if(result?.next_stream?.id){
            setNextStream(result?.next_stream)
        }

        if(prevShow?.data?.[0]?.id){
            setPrevStream(prevShow?.data?.[0])
        }

        setLoading(false)
        await AddHistory(currentProfile?.id, { data:{ stream_show:video_id } }) 
    }


    const nextShow = () => { 
        navigate(`dashboard/player/${ nextStream.id }/running`);
    }
    
    const prevShow = () => {  
        navigate(`dashboard/player/${ prevStream.id }/running`)
    }
    
    useEffect(() => {
        if(video_id && currentProfile){ loadVideo() ;}
    }, [video_id, currentProfile])

    // useEffect(() => {
    //     if(playing){
    //         emitPlaying(video_id)
    //     } else {
    //         emitStoping(video_id)
    //     }
    // }, [playing])

    useEffect(() => {
        if(playing){
            emitPlaying(video_id)
        }
    }, [playing])

    useEffect(() => {
        return () => { emitStoping(video_id) }
    }, [])


    useEffect(() => {
        if( videoUrl && typeof videoRef?.current?.addEventListener === 'function' ){
                const fireOnVideoEnd = () => {  
                    navigate(`dashboard/player/${ nextStream.id }/running`);
                }
                videoRef.current.addEventListener('ended', fireOnVideoEnd);
            return () => {
                videoRef.current.removeEventListener('ended', fireOnVideoEnd); 
            }
        }
    }, [ nextStream, videoUrl, videoRef?.current ]);

    const [currentProgressPlayer, setCurrentProgressPlayer] = useState(null)
    const [currentTime, setCurrentTime] = useState(0)
    const [seek, setSeek] = useState(0)
    const [duration, setDuration] = useState(0)

    const onSeek = time => {
        if(videoRef?.current){
            videoRef.current.currentTime = time;
        }
    }

    useEffect(() => {
        const video = videoRef.current;

        const updateTime = () => {
            setCurrentTime(video.currentTime);
            setDuration(video.duration);
        }

        if(video){
            video?.addEventListener('timeupdate', updateTime) 
            return () => {
                video?.removeEventListener('timeupdate', updateTime) 
            }
        }
    }, [ videoRef?.current ])

    const [waiting, setWaiting] = useState(false)

    useEffect( () => { 

        const saveProgress = async () => {
            if(currentTime && currentProfile?.id && video_id && !waiting && playing){
                setWaiting(true)
                const payload = {
                    data:{
                        progress: parseInt(currentTime),
                        profile: currentProfile?.id,
                        stream_show: video_id,
                        data:{
                            user,
                            profile: currentProfile,
                            duration,
                            finishing: ((parseInt(currentTime) + 240) > duration) 
                        }
                    }
                }
                const created = currentProgressPlayer?.id ? await UpdateProgressPlayer(currentProgressPlayer?.id, payload) : await CreateProgressPlayer(payload)
                // console.log("Progress SAVED", created?.data?.attributes?.progress)
                setTimeout(() => { setWaiting(false) ;}, 15000)
            }
        }

        saveProgress()

    }, [ currentTime, currentProfile, video_id, waiting ])

    useEffect( () => { 
        if( videoRef?.current && playing && seek ){
            setSeek(0);
            onSeek(seek)
        }
    }, [ playing, seek ])

    return ( 
        <>
            <ContainerProfile fluid>   
                <ContainerVideoPlayer>
                        { 
                            videoUrl ? 
                                <video 
                                    ref={videoRef} 
                                    autoPlay={!!running}
                                    controls={true}
                                    height={'100%'} 
                                    width="100%"
                                    onPause={() => setPlaying(false)} 
                                    onPlay={() => setPlaying(true) }  
                                    style={{ objectFit:'contain', background: 'black' }} >
                                    <source src={parseStrapiImage(videoUrl)} type="video/mp4" /> 
                                </video> : null
                        }
                        
                        {/* {
                            videoUrl ? 
                                <ReactHlsPlayer
                                    playerRef={videoRef} 
                                    src={parseStrapiImage(videoUrl)}
                                    autoPlay={!!running}
                                    controls={true}
                                    width="100%"
                                    height="100%"
                                    onPause={() => setPlaying(false) }
                                    onPlay={() => setPlaying(true) } 
                                    style={{ objectFit:'fill' }}
                                />
                            : null
                        } */}
                </ContainerVideoPlayer>
                {
                    playing ? null : 
                    <ContainerVideo> 
                        
                        <VideoHeader preview={previewMode}>
                            <VideoHeaderSection>
                                <VideoHeaderBack onClick={() => history.goBack()} />
                                <VideoHeaderImage image={details?.thumb} />
                                <VideoHeaderContent>
                                    {/* <VideoHeaderSubtitle>{ details?.title }</VideoHeaderSubtitle> */}
                                    <VideoHeaderTitle>{ details?.title }</VideoHeaderTitle>
                                </VideoHeaderContent>
                            </VideoHeaderSection>
                            <VideoHeaderSection flex>
                                {/* { previewMode ? <DashboardPlayerProgress  item={{ id: video_id }}  /> : null } */}
                                <VideoHeaderAction onClick={togglePreview}>
                                    <VideoHeaderActionIcon active={previewMode} />
                                </VideoHeaderAction>
                            </VideoHeaderSection>
                        </VideoHeader>

                        {
                            previewMode ? null : <>
                                <VideoCenterContent>
                                    {
                                        !prevStream ? null :
                                        <VideoCenterAction onClick={prevShow} />
                                    }
                                    <VideoCenterPlay onClick={playPause}>
                                        <VideoCenterPlayIcon />
                                    </VideoCenterPlay>
                                    {
                                        !nextStream ? null :
                                        <VideoCenterAction next onClick={nextShow} />
                                    }
                                </VideoCenterContent>
                                <DashboardPlayerProgress item={{ id: video_id }} progress={(currentTime*100)/(duration||1)} />
                            </>
                        }

                        <VideoBottomContent>
                            <VideoBottomAction onClick={togglePreview}>
                                <VideoBottomActionIcon active={previewMode} />
                            </VideoBottomAction>
                            <Container>
                                <VideoBottomWrapper>
                                    { loading ? <Load white marginBottom={20} /> : null }
                                    {
                                        relateds.map((item, key) => 
                                            <VideoBottomVideo key={key} image={item.cover} onClick={() => navigate(`dashboard/player/${ item.id }`)} />
                                        )
                                    }
                                </VideoBottomWrapper>
                            </Container>
                        </VideoBottomContent>
                        
                    </ContainerVideo>
                } 
            </ContainerProfile> 
        </>
    );

}