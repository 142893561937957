import styled from 'styled-components' 
import ReactLoading from 'react-loading';
import Lottie from 'react-lottie';

export const hexToRgb = (hex) => {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return [(c>>16)&255, (c>>8)&255, c&255].join(',') ;
    }
    return `255,255,255`
} 

export const PrimaryColor = hexToRgb('#222222')

export const Touch = styled.div.attrs({ 
})`         
    cursor: pointer;
    &:hover{
        box-shadow: 0px 1px 3px var(--shadow-color);
    }
`;

export const Load = styled(ReactLoading).attrs(props => ({ 
    type:'spin',
    color: props.primary ? '#4527a0' : '#ffffff',
    height: props.big ? 40 : 20,
    width: props.big ? 40 : 20
}))`         
    cursor: pointer;
    &:hover{
        box-shadow: 0px 1px 3px var(--shadow-color);
    }
`;

export const ButtonWhite = styled.div.attrs({ 
})`     
    min-width: 176px;
    height: 41px;
    padding: 0 12px;
    border-radius: 2px;
    margin-left: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    ${
        props => props.outline ? `
            border: 1px solid var(--white-color);
            color: var(--white-color);
        ` : `
            background-color: var(--white-color);
            color: var(--primary-color);
        `
    }
    ${
        props => props.nomargin ? `
            margin: 0 !important;
        ` : ``
    }
    ${
        props => props.centred ? `
            margin: 12px auto;
        ` : ` 
        `
    }
 
    font-size: 15px;
    font-weight: 600;
    text-align: center; 

    @media(max-width: 869px){ 
        margin-left: 12px; 
    }

    @media(max-width: 809px){
        ${
            props => props.outline ? `
                min-width: 120px;
            ` : `
                margin-left: 0;
            `
        }
    }
 
    @media(max-width: 767px){  
        margin-left: 30px; 
    }
    
`;
    
export const ButtonColor = styled.div.attrs({ 
})`      

    ${
        props => props.outline ? `
            border: 1px solid var(--lightgrey-color);
            color: var(--lightgrey-color);
        ` : `
            background: var(--lightgrey-color);
            color: var(--white-color);
        `
    } 
    height: 45px; 
    width: 100%;
    flex:1;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
 
    font-weight: bold;
    font-size: 15px;
    margin-top: 20px;
    border-radius: 5px;

    cursor: pointer; 
    ${
        props => props.primary ? `
            ${
                props.outline ? `
                    border: 1px solid var(--primary-color);
                    color: var(--primary-color);
                ` : `
                    background: var(--primary-color);
                    color: var(--white-color);
                `
            }
        ` : ``
    }
    ${
        props => props.secondary ? ` 
            ${
                props.outline ? `
                    border: 1px solid var(--secondary-color);
                    color: var(--secondary-color);
                ` : `
                    background: var(--secondary-color);
                    color: var(--white-color);
                `
            }
        ` : ``
    }
    ${
        props => props.accent ? ` 
            ${
                props.outline ? `
                    border: 1px solid var(--accent-color);
                    color: var(--accent-color);
                ` : `
                    background: var(--accent-color);
                    color: var(--white-color);
                `
            }
        ` : ``
    }
    ${
        props => props.white ? ` 
            ${
                props.outline ? `
                    border: 1px solid var(--white-color);
                    color: var(--white-color);
                ` : `
                    background: var(--white-color);
                    color: var(--white-color);
                `
            }
        ` : ``
    }
    ${
        props => props.centred ? `
            margin: 12px auto;
        ` : ` 
        `
    }
`;
 
export const Animation = styled(Lottie).attrs( props => ({  
    options:{
        loop: true,
        autoplay: true, 
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
        animationData: props.animationData
    }, 
    width: props.width ? props.width : 320
}))`        
    max-width: 100%;
`;  


export const CenterLoad = styled.div.attrs({ 
})`           
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px;
`;


export const SpacerMore = styled.div.attrs({ 
})`
    padding: 5vh;
`;
