import React, { useContext, useState } from "react";  

import {     

    FaqItem,
    FaqItemAsk,
    FaqItemAnswer,

    FaqItemHeader,
    FaqItemIcon

} from "./styled"; 

export default function Faq({ ask, answer, landpage }){    

    const [ open, setOpen] = useState(landpage ? true : false)

    return ( 
        <>  
            <FaqItem landpage={landpage}>
                <FaqItemHeader onClick={() => setOpen(!open)}>
                    <FaqItemAsk landpage={landpage}>{ ask }</FaqItemAsk>
                    <FaqItemIcon landpage={landpage} open={open} />
                </FaqItemHeader>
                { open ? <FaqItemAnswer landpage={landpage} dangerouslySetInnerHTML={{__html: answer }} /> : null }
            </FaqItem> 
        </>
    );

}