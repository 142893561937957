import React, { useContext, useEffect, useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';
  

import { 
    
    BannerContent, 
    BannerCard, 
    BannerTitle,
    ContentCardsPlan,
    ContentCardsPlanInstagram,
    ContentCardsPlanFacebook, 
    

} from "./styled"; 

export default function Footer(){

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`); 
    return ( 
        <>  
            <BannerContent>
                <Container>
                    <Row>
                        <Col md={{ size: 12 }}> 
                            <BannerCard>

                                <BannerTitle> Todos os direitos reservados. Hoxx Tv é usado sob licença. </BannerTitle> 

                                <ContentCardsPlan>
                                    <ContentCardsPlanInstagram />
                                    <ContentCardsPlanFacebook />
                                </ContentCardsPlan>

                            </BannerCard>
                        </Col>
                    </Row>
                </Container>
            </BannerContent>
        </>
    );
}