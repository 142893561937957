import React, { useContext, useEffect, useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';
  

import { 
    BannerImage,
    BannerOut,
    BannerContent, 
    BannerCard, 
    BannerTitle, 
    ContentCardsPlan,
    ViewPreview,
    ContentActions, 


} from "./styled"; 
import Button from "components/Button";
import { ReadPremiumPlans } from "services/authentication"; 
import { parsePlan } from "utils/parser";
import { CoreContext } from "context/CoreContext";
import { parseCurrency } from "utils";
import { ReadObject } from "services/storage";

export default function Catalog({ collection }){

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);  
 
    const openVideo = (mit) => {
        const authentication = ReadObject('authentication')
        if (!authentication?.jwt) {
            navigate(`login`)
            return
        }
        navigate(`dashboard/player/${mit.id}`)
    }


    return ( 
        <>  
            <BannerImage>
                <BannerOut />
                <BannerContent>
                    <Container>
                        <Row>
                            <Col md={{ size: 12 }}> 
                                <BannerCard>

                                    {
                                        collection?.filter(f => f.id === 1 || f.id === 3 )?.map((item, key) => <div key={key}>
                                            <BannerTitle> { item?.title } </BannerTitle>
                                            <ContentCardsPlan>
                                                {
                                                    [...(item?.videos||[])]?.slice(0, 4)?.map((mm, kk) => 
                                                        <ViewPreview key={`${key}-${kk}`} image={mm?.cover} onClick={() => openVideo(mm)}/>
                                                     )
                                                }
                                            </ContentCardsPlan>
                                        </div>)
                                    }
                                    
                                    <ContentActions>
                                        <Button secondary onClick={() => navigate('register')}>Assine agora</Button>
                                    </ContentActions>

                                </BannerCard>
                            </Col>
                        </Row>
                    </Container>
                </BannerContent>
            </BannerImage>
        </>
    );
}