import React, { useContext, useEffect, useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';
  

import { 
    
    BannerContent, 
    BannerCard, 
    BannerTitle, 
    ContentCardsPlan,
    ViewPreview,
    ContentActions, 

    RewviewDecoration,
    ReviewTitle,
    ReviewText,
    ReviewRate,
    ReviewRateIcon


} from "./styled"; 
import Button from "components/Button";
import { ReadAbout, ReadPremiumPlans } from "services/authentication"; 
import { parsePlan } from "utils/parser";
import { CoreContext } from "context/CoreContext";
import { parseCurrency } from "utils";
import Faq from "components/Dashboard/Faq";
import { Load } from "ui/styled";

export default function FAQLandpage(){

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const [searchExpression, setSearchExpression] = useState('')

    const { backgrounds } = useContext(CoreContext)

    const [loading, setLoading] = useState(false)
    const [help, setHelp] = useState([])
    const [contact, setContact] = useState({})

    const init = async () => {
        setLoading(true)
        const result =  await ReadAbout()
        if(result?.help?.length){
            setHelp(result?.help?.map(mit => ({ ...mit, ask: mit.question }) ))
        }
        if(result?.contact?.id){
            setContact(result?.contact)
        }
        setLoading(false)
    }


    const filterExpression = item => {
        return ( !searchExpression || Object.keys(item).filter(k => `${ item[k] }`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1 ).length > 0)
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>  
            <BannerContent>
                <Container>
                    <Row>
                        <Col md={{ size: 12 }}> 
                            <BannerCard>

                                <BannerTitle> A sua dúvida está por aqui </BannerTitle>
                                <ContentCardsPlan>
                                    
                                    {
                                        loading ? <Load primary="true" /> :
                                        help.filter(filterExpression).map((item, key) => 
                                            <Faq ask={item.ask} answer={item.answer} landpage />                            
                                        )
                                    }


                                    
                                </ContentCardsPlan>
                                

                            </BannerCard>
                        </Col>
                    </Row>
                </Container>
            </BannerContent>
        </>
    );
}