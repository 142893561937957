import styled from 'styled-components' 

export const BannerImage = styled.div.attrs({ 
})`           

    position: relative;
    min-height: calc(100vh - 80px);
    width: 100%;

    background: var(--black-color) url(/images/banner-hoxx.png) no-repeat center center / cover;
`; 

export const BannerOut = styled.div.attrs({ 
})`            
    min-height: calc(100vh - 80px);
    width: 100%;

    background: var(--shadow-color); 
    mix-blend-mode: multiply;
`; 

const degradeeColor = "93,0,0"
// const degradeeColor = "249,94,20"

export const BannerContent = styled.div.attrs({ 
})`           
    min-height: calc(100vh - 80px);
    width: 100%; 

    background: -moz-linear-gradient(180deg, rgba(0,0,0,.3) 5%, rgba(0,0,0,.0) 55%, rgba(${ degradeeColor },.5) 110%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,.3) 5%, rgba(0,0,0,.0) 55%, rgba(${ degradeeColor },.5) 110%);
    background: linear-gradient(180deg, rgba(0,0,0,.3) 5%, rgba(0,0,0,.0) 55%, rgba(${ degradeeColor },.5) 110%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(0,0,0,.3)",endColorstr="rgba(${ degradeeColor },.4)",GradientType=1);

    position: absolute;
    top: 0;
    left: 0;
`; 

export const BannerCard = styled.div.attrs({ 
})`        
    max-width: 520px;
    margin: 0 auto;
    padding: calc(50vh - 210px) 0 0;
`; 




export const BannerCenterContent = styled.div.attrs({ 
})`            
    display: flex;
    flex-direction: column;
    align-items: center;
`; 

export const BannerCenterActions = styled.div.attrs({ 
})`            
    margin: 40px auto 40px;
    flex:1;
    max-width: 250px;
`; 



export const BannerLogo = styled.img.attrs({ 
    src:'/icons/logo.svg'
})`           
    margin: 125px auto 20px;
`; 





export const BannerTitle = styled.h1.attrs({ 
})`       
    font-size: 32px;
    color: var(--white-color);    

    text-align: center;
    opacity: .8;
`; 
export const BannerSubtitle = styled.div.attrs({ 
})`           
    font-size: 48px;
    color: var(--white-color);
    font-weight: bold;

    text-align: center; 
    margin: 20px auto;
    max-width: 340px;
`; 
export const BannerText = styled.div.attrs({ 
})`           
    font-size: 18px;
    color: var(--white-color);    

    text-align: center;
`; 
export const BannerSubtext = styled.div.attrs({ 
})`           
    font-size: 18px;
    color: var(--white-color);    
 
    margin: 0 auto;
    max-width: 400px;
    text-align: center;
    opacity: .7;
`; 



export const LoginContent = styled.div.attrs({ 
})`           
    margin: 0 0 0 auto;
    width: 160px;
    color: var(--white-color);   
`; 





export const ContentHeader = styled.div.attrs({ 
})`
    padding: 16px;
    background: var(--darkprimary-color); 
    display: flex;
    justify-content: center;
`; 
export const ContentHeaderIcon = styled.div.attrs({ 
})`
    width: 164px;
    height: 48px;
    background: url(/images/logo-hoxx.png) no-repeat center center / contain;
    cursor: pointer;
`; 