import styled from 'styled-components' 



export const BannerContent = styled.div.attrs({ 
})`           
    width: 100%; 
 

    background: #000;

    position:relative;
    z-index:2;
`; 

export const BannerCard = styled.div.attrs({ 
})`        
    // max-width: 920px;
    margin: 0 auto;
    padding: 30px 0 0;
`; 



export const BannerTitle = styled.h1.attrs({ 
})`       
    font-size: 18px;
    color: var(--white-color);
    margin: 0 0 30px;
    text-align: center;
`;  


export const ContentCardsPlan = styled.div.attrs({ 
})`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: 30px;
    padding: 20px 0;
`; 




export const ContentCardsPlanInstagram = styled.img.attrs({ 
    src:'/images/social-1.png',
    width: 50
})`
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`; 

export const ContentCardsPlanFacebook = styled.img.attrs({ 
    src:'/images/social-2.png',
    width: 50
})`
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`; 


