import React, { useContext, useEffect, useRef, useState } from "react";  

import {     
    DashboardContent, 

    DashboardTitle,
    DashboardWrapper,

    DashboardThemeItem,
    DashboardUserItem,
    ScrollSpacer
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 
import DashboardRowVideos from "components/Dashboard/RowVideos";

import { availableCatagories, availableVideos } from "utils/mock";
import { CoreContext } from "context/CoreContext";
import SearchInput from "components/Dashboard/SearchInput";
import { ReadKids, ReadSearch, ReadSearchByCharacter, ReadSearchByTheme, ReadSerchableCharacteres, ReadSerchableThemes } from "services/catalog";
import { CenterLoad, Load } from "ui/styled";
import { parseVideo } from "utils/parser";
import { ReadMyHistory } from "services/account";
import { AddSearchHistory, ReadSearchHistory } from "services/history";

export default function DashboardFavorite(){   

    const { currentCategory, currentProfile } = useContext(CoreContext)

    const [themes, setThemes] = useState([])
    const [users, setUsers] = useState([])
    const [myHistory, setMyHistory] = useState([])

    const scrollRef = useRef()

    const [loading, setLoading] = useState(false)
    const [searched, setSearched] = useState('')
    const [foundedVideos, setFoundedVideos] = useState([])
    const [previousSearchs, setPreviousSearchs] = useState([])
    
    const [allowedVideos, setAllowedVideos] = useState([])

    const search = async (searchExpression) => {
        
        setSearched(searchExpression)
        setLoading(true)
        const result = await ReadSearch(searchExpression)
        await AddSearchHistory({ data:{ term: searchExpression } }, currentProfile.id)
        if(result?.data?.length){ 
            setFoundedVideos((currentProfile?.kids ? result.data?.filter(f => allowedVideos.includes(f.id) ) : result.data).map(parseVideo)) 
        }else{
            setFoundedVideos([])
        } 
        setLoading(false)

    }

    const searchBy = async (type, item) => {
        
        setLoading(true)
        
        setSearched( item.title ? item.title : item.name )
        const result = (type === 'theme') ? await ReadSearchByTheme(item.id) : await ReadSearchByCharacter(item.id)
        await AddSearchHistory({ data:{ [ (type === 'theme') ? 'themes' : 'characters' ]: item.id } }, currentProfile.id)

        if(result?.data?.length){ 
            setFoundedVideos(result.data.map(parseVideo)) 
        }else{
            setFoundedVideos([])
        } 
        setLoading(false)

    }

    const init = async () => {
        setLoading(true)
        // const resultThemes = await ReadSerchableThemes()
        // const resultCharacteres = await ReadSerchableCharacteres()

        if(currentProfile?.kids){
            const resultKids = await ReadKids()
            if(resultKids?.data?.[0]?.attributes?.stream_shows?.data?.length){
                setAllowedVideos(resultKids?.data?.[0]?.attributes?.stream_shows?.data?.map(m => m.id))
            }
        }

        const resultHistory = currentProfile ? await ReadMyHistory(currentProfile.id) : false 
        // const resultPrevious = currentProfile ? await ReadSearchHistory(currentProfile.id) : false 

        // if(resultThemes?.data?.length){ setThemes( resultThemes?.data?.map( item => ({ ...item, title:item?.descriptor }) ) ) ;}
        // if(resultCharacteres?.data?.length){ setUsers( resultCharacteres?.data?.map( item => ({ ...item, image: item?.picture?.url }) ) ) ;}
        if(resultHistory?.data?.length){ setMyHistory( resultHistory?.data?.map( mit => mit.stream_show )?.map( parseVideo ) ) ;}
        // if(resultPrevious?.data?.length){ setPreviousSearchs( resultPrevious?.data ) ;}
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [currentProfile])


    return ( 
        <>
            <ContainerAuthenticated primary page={'search'} scrollRef={scrollRef}>  

                { loading ? 
                    <CenterLoad>
                            <Load white marginBottom={20} /> 
                    </CenterLoad> : <>
                        <SearchInput placeholder={'O que você quer assistir?'} onSearch={search} searched={searched} /> 

                        {
                            searched ? <>
                                <DashboardRowVideos title={ foundedVideos?.length ? `Resultados para: ${ searched }` : `Sem resultados para: ${ searched }` }  parentScrollRef={scrollRef} wrapper videos={foundedVideos} />  
                            </> : <>
                                {/* <DashboardContent>
                                    <DashboardTitle>Buscar por temas</DashboardTitle>
                                    <DashboardWrapper>
                                        {
                                            themes.map((item, key) => 
                                                <DashboardThemeItem key={key} onClick={() => searchBy('theme', item)}>{ item.title }</DashboardThemeItem>
                                            )
                                        }
                                    </DashboardWrapper>
                                </DashboardContent>
                
                                <DashboardContent>
                                    <DashboardTitle>Buscar por personagens</DashboardTitle>
                                    <DashboardWrapper>
                                        {
                                            users.map((item, key) => 
                                                <DashboardUserItem key={key} image={item.image} onClick={() => searchBy('character', item)} />
                                            )
                                        }
                                    </DashboardWrapper>
                                </DashboardContent> */}
                
                                {   
                                    !myHistory?.length ? null :
                                    [{ title:'Últimos visualizados', id:1 }].slice(0,1).map((cat, kcat) => 
                                        <DashboardRowVideos key={kcat} title={ cat.title } parentScrollRef={scrollRef} wrapper videos={myHistory} /> 
                                    )
                                } 
                            </>
                        }
        
                    </> 
                }
                <ScrollSpacer />
            </ContainerAuthenticated> 
        </>
    );

}