import styled from 'styled-components'  

export const BannerCarousel = styled.div.attrs({ 
})`              
    scroll-snap-type: x mandatory; 

    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const BannerContent = styled.div.attrs({ 
})`             
    min-height: 50vh;
    min-width: 100vw;
    background: url(${ props => props.image }) no-repeat center center / cover;

    scroll-snap-align: start;
`;
export const BannerCoverShadow = styled.div.attrs({ 
})` 
    height: 100%;            
    background: linear-gradient(45deg, rgba(36,36,36, .9) 0%, rgba(36,36,36, .75) 25%, rgba(36,36,36, .6) 50%, rgba(36,36,36, .4) 75%, rgba(36,36,36, .25) 100%);
    padding: 10vh 0;
`;
export const BannerItem = styled.div.attrs({ 
})`             
`;
export const BannerTitle = styled.div.attrs({ 
})`             
    font-size: 50px;
    font-weight: bold;
    color: var(--white-color);
    margin-bottom: 8px;
`;
export const BannerCategoryContent = styled.div.attrs({ 
})`             
    margin-bottom: 16px;
    display: flex;
`;
export const BannerCategoryIcon = styled.img.attrs(props => ({ 
    src:`/icons/star-${ props.color }.svg`
}))`             
    margin-right 4px;
`;
export const BannerCategoryText = styled.div.attrs({ 
})`             
    font-size: 20px; 
    color: var(--white-color);
`;
export const BannerText = styled.div.attrs({ 
})`         
    font-size: 18px;
    font-weight: bold;
    color: var(--white-color);    
    max-width: 484px;
`; 

export const BannerButtonsContent = styled.div.attrs({ 
})`             
    display: flex;
    gap: 0 13px;
    max-width: 325px;
`;

export const ButtonIcon = styled.img.attrs(props => ({ 
    src:`/icons/${ props.icon }.svg`
}))`             
    margin-right 12px;
`;


 



export const StepIndicator = styled.div.attrs({ 
})`       
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 20px;       

    margin-top: -4px;
`;

export const StepItem = styled.div.attrs({ 
})`             
    width: 55px;
    height: 2px;
    ${
        props => props.active ? `
            background: var(--secondary-color);
        ` : `
            background: var(--white-color);
        `
    }
`;