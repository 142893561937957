import React, { useEffect, useState } from "react";  

import {    
    DashboardSpacer,

    PageTitle,
    PageSubtitle, 

} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 

import { Container, Row, Col } from "reactstrap";
import SearchInput from "components/Dashboard/SearchInput";
import Faq from "components/Dashboard/Faq";
import Button from "components/Button";
import { Load } from "ui/styled";
import { ReadAbout } from "services/authentication";

export default function DashboardAbout(){   

    const [searchExpression, setSearchExpression] = useState('')

  
    const [loading, setLoading] = useState(false)
    const [help, setHelp] = useState([])
    const [contact, setContact] = useState({})

    const init = async () => {
        setLoading(true)
        const result =  await ReadAbout()
        if(result?.help?.length){
            setHelp(result?.help?.map(mit => ({ ...mit, ask: mit.question }) ))
        }
        if(result?.contact?.id){
            setContact(result?.contact)
        }
        setLoading(false)
    }

    const openWhats = () => {
        window.open(`https://api.whatsapp.com/send?phone=${ contact?.whatsapp?.replace(/\(|\)|\ |\-/g, '') }`)
    }

    const openMail = () => {
        window.open(`mailto:${ contact?.email }`)
    }

    const filterExpression = item => {
        return ( !searchExpression || Object.keys(item).filter(k => `${ item[k] }`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1 ).length > 0)
    }
 
    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
            <ContainerAuthenticated>  
                <Container>
                    <Row>
                        <Col md={{ size: 10, offset:1 }}>
                            <DashboardSpacer />
                            <PageTitle>Ajuda</PageTitle>
                            <SearchInput outline placeholder={"Qual sua dúvida?"} value={searchExpression} onChange={e => setSearchExpression(e.target.value)} />
                            <PageSubtitle>Dúvidas comuns</PageSubtitle> 
                            {
                                loading ? <Load primary="true" /> :
                                help.filter(filterExpression).map((item, key) => 
                                    <Faq ask={item.ask} answer={item.answer} />                            
                                )
                            }
                            <PageSubtitle>Fale Conosco:</PageSubtitle> 
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 4, offset:4 }}>

                            <Button primary onClick={openWhats}>WhatsApp</Button>
                            <Button primary outline onClick={openMail}>E-Mail</Button>
                            <DashboardSpacer />
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated> 
        </>
    );

}