import React, { useContext, useEffect, useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';
  

import { 
    BannerImage,
    BannerOut,
    BannerContent, 
    BannerCard, 
    BannerTitle, 
    ContentCardsPlan,
    ViewPreview,
    ContentActions, 


} from "./styled"; 
import Button from "components/Button";
import { ReadPremiumPlans } from "services/authentication"; 
import { parsePlan } from "utils/parser";
import { CoreContext } from "context/CoreContext";
import { parseCurrency } from "utils";
import { ReadObject } from "services/storage";

export default function CatalogPopular({ collection }){

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);
 
    const openVideo = (mit) => {
        const authentication = ReadObject('authentication')
        if (!authentication?.jwt) {
            navigate(`login`)
            return
        }
        navigate(`dashboard/player/${mit.id}`)
    }

    return ( 
        <>  
            <BannerImage>
                <BannerOut />
                <BannerContent>
                    <Container>
                        <Row>
                            <Col md={{ size: 12 }}> 
                                <BannerCard>

                                    <BannerTitle> Conteúdos mais assistidos </BannerTitle>
                                    <ContentCardsPlan>
                                        {
                                            [...(collection?.find(f => f.id === 5)?.videos||[])]
                                                ?.slice(0, 6)
                                                    ?.map((mm, kk) => 
                                                    <ViewPreview key={`${kk}`} image={mm?.cover} onClick={() => openVideo(mm)}/>
                                                )
                                        }                                        
                                    </ContentCardsPlan>  
                                    
                                </BannerCard>
                            </Col>
                        </Row>
                    </Container>
                </BannerContent>
            </BannerImage>
        </>
    );
}