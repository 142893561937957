import React, { useContext, useState } from "react"; 
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Input from 'components/Input'

import {  
    CentredContent,
    LoginSpacer,
    WhiteCard,
    CardTitle,
    CardText,

    WrapUsers,
    UserItem,
    UserTitle,
    UserSelected


} from './styled' 

import ContainerProfile from "containers/Profile";
import Button from "components/Button";
import { CoreContext } from "context/CoreContext";
import { parseStrapiImage } from "utils";

export default function SelectProfile(){ 
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);  

    const { profiles, setProfiles, currentProfile, setCurrentProfile, backgrounds } = useContext(CoreContext)

    const start = () => { 
        navigate('dashboard')
    }  
    
    const createProfile = () => { 
        navigate('dashboard/create-profile')
    }  
    
    const getConcordance = () => {
        return currentProfile?.gender?.type === 'male' ? 'o' : 'a'
    } 
    
    console.log('profiles', profiles)
    return ( 
        <>  
            <ContainerProfile image={parseStrapiImage(backgrounds?.find(fnd => fnd.locale === 'choose_profile')?.url)}>  
                <CentredContent big={!currentProfile?.id}> 

                    <WhiteCard>
                        {
                            !!currentProfile?.id ? <>
                                <UserSelected image={`${ currentProfile?.avatar?.image }`} />
                                <UserTitle> { currentProfile?.name } </UserTitle>
                                <CardTitle centred>Bem vind{ getConcordance() }!!</CardTitle>
                            </> : <>
                                <CardTitle>Quem vai assistir?</CardTitle>
                            </>
                        } 
                        <WrapUsers>
                            {
                                [0,1,2].map((item, key) => {
                                    const cproof = profiles?.[key]
                                    return (
                                        <UserItem key={key} image={ cproof ? cproof?.avatar?.image : null } onClick={ cproof ? () => setCurrentProfile(cproof) : () => createProfile()}  />
                                    )   
                                })
                            } 
                        </WrapUsers>
                        { profiles?.length < 3 ? <CardText>Você { profiles?.length > 0 ? 'ainda' : '' } pode <b>Adicionar</b> { profiles?.length > 0 ? 'mais' : '' } <b>{ 3 - (profiles?.length || 0) }</b> Perfis!</CardText> : null }
                    </WhiteCard>
                    { profiles?.length < 3 ? <Button outline white onClick={createProfile}>+ Criar perfil</Button> : null }
                    <Button primary onClick={start}>Começar</Button>
                    
                </CentredContent>
            </ContainerProfile> 
        </>
    );
}