import { toast } from 'react-toastify';
import { API_ENDPOINT } from 'services/api'

export const exposeStrapiError = result => { 

    if( !result ){
        toast.error( 'Cant connect to server, try again later')
        return true;
    }
    
    if(result?.error && result?.data?.[0]?.messages?.[0]?.message && typeof result?.data?.[0]?.messages?.[0]?.message === 'string'){
        toast.error(result?.data?.[0]?.messages?.[0]?.message )
        return true;
    }else if(result?.error && result?.message?.[0]?.messages?.[0]?.message && typeof result?.message?.[0]?.messages?.[0]?.message === 'string'){
        toast.error(result?.message?.[0]?.messages?.[0]?.message )
        return true;
    }else if(result?.error && result?.data?.[0]?.message && typeof result?.data?.[0]?.message === 'string'){
        toast.error(result?.data?.[0]?.message )
        return true;
    }else if(result?.error && result?.message && typeof result?.message === 'string'){
        toast.error(result?.message )
        return true;
    }else if(result?.error && result?.error?.message && typeof result?.error?.message === 'string'){
        toast.error(result?.error?.message )
        return true;
    }

    if(result?.error && result?.error?.details?.errors?.length ){
        result?.error?.details?.errors?.map( item => {
            toast.error(item?.message )
            return true;
        })
        return true;
    }
    
    return false;
}
 
export const normalizeStrapiList = result => {
    return result?.data?.length ? result?.data.map(item => ({
        id: item.id,
        ...item.attributes
    })) : result 
}
 
export const normalizeStrapiRegisterSolo = result => { 
    return result?.id && result?.attributes ? { 
        id: result?.id,  
        ...result?.attributes
    } : result?.data?.id ? result?.data?.attributes ? {
        id: result?.data?.id,  
        ...result?.data?.attributes
    } : result?.data : result
}

export const normalizeStrapiRegister = result => { 
    let results = normalizeStrapiRegisterSolo(result)  
    Object.keys(results).map(attrKey => {
        results[attrKey] = results[attrKey]?.data ? normalizeStrapiRegisterSolo(results[attrKey]) : results[attrKey]
        results[attrKey] = typeof results[attrKey]?.data === 'object' ? results[attrKey]?.data : results[attrKey]
        results[attrKey] = results[attrKey]?.length && results[attrKey]?.[0]?.attributes ? 
            results[attrKey]?.map(normalizeStrapiRegisterSolo)
        : results[attrKey] 


        return true;
    }) 
    return results;
}
 
export const normalizeStrapiPayload = (form, formtypes) => {

    formtypes.map(item => {
        if(form[item.name]){ 
            if((item.type === 'float' || item.type === 'decimal') && item.name === 'price' && typeof form[item.name] === 'string' ){
                form[item.name] = parseFloat(form[item.name].replace(/R|\$|\.| /g, '').replace(',','.'))
            }
    
            if( item.type === 'float' || item.type === 'decimal' ){
                form[item.name] = parseFloat(form[item.name])
            }
    
            if( item.type === 'biginteger' || item.type === 'integer' ){
                form[item.name] = parseInt(form[item.name])
            }
            
            if( (item.type === 'date' || item.type === 'time' || item.type === 'datetime') && typeof form[item.name]?.getFullYear === 'function' ){
                form[item.name] = parseStrapiDate(form[item.name])
            }
            
            if( item.type === 'time' ){
                form[item.name] = (`${form[item.name]}`.split("T")).pop() 
            }
            
            if( item.type === 'date' ){
                form[item.name] = `${(`${form[item.name]}`.split("T")).shift()}` 
            } 
            
        }
        return true;
    })

    return { ...form, data:{...form}}
}

export const numerize = number => {
    return parseInt(number) < 10 ? `0${number}` : `${number}`
}

export const parseStrapiDate = date => {  
    return typeof date?.getFullYear !== 'function' ? date : `${date.getFullYear()}-${numerize(date?.getMonth()+1)}-${numerize(date?.getDate())}T${numerize(date?.getHours())}:${numerize(date?.getMinutes())}:${numerize(date?.getSeconds())}`
} 
 
export const parseStrapiImage = url => {
    return url?.indexOf('://') !== -1 ? url : url?.indexOf('hoxxtv.b-cdn.net') !== -1 ? `https://${url}` :  `${API_ENDPOINT.replace('/api', '')}${url}`
}

export const normalizeDate = (date, type = 'datetime') => { 
    if( (type === 'date' || type === 'time' || type === 'datetime') && typeof date?.getFullYear === 'function' ){
        date = parseStrapiDate(date)
    }
    
    if( type === 'time' ){
        return (`${date}`.split("T")).pop() 
    }
    
    if( type === 'date' ){
        return `${(`${date}`.split("T")).shift()}` 
    } 

    return date
}

export const findShowableParam = item => {
    
    const notAllowed = [ 
        'id',
        'createdAt',
        'updatedAt',
        'createdBy',
        'updatedBy',
        'publishedAt',
        'attributes',
        'created_by',
        'updated_by',
        'created_at',
        'updated_at',
        'published_by',
        'published_at',
    ]
    return Object.keys(item).filter(fit => !notAllowed.includes(fit) ).shift()
}

export const filterSystemParams = (fit, attrs, item) => {

    const notAllowed = [ 
        'id',
        'createdAt',
        'updatedAt',
        'createdBy',
        'updatedBy',
        'publishedAt',
        'created_by',
        'updated_by',
        'created_at',
        'updated_at',
        'published_by',
        'published_at',
    ]

    if(item.uid.indexOf('plugin::') !== -1){
        notAllowed.push('provider')
        notAllowed.push('role')
    } 

    return !notAllowed.includes(fit) && (!attrs.private || attrs.type === 'password')
} 

export const capitalize = (s) => {
    if(!s){ return `` ;}
    let vs = `${s}`
    return `${ vs.charAt(0).toUpperCase() }${vs.slice(1)}`;
}

export const isCpf = (c) => {
    if((c = c.replace(/[^\d]/g,"")).length != 11) return false;
    if (c == "00000000000") return false;
    let r;
    let s = 0;   
    for (let i=1; i<=9; i++) s = s + parseInt(c[i-1]) * (11 - i); 
    r = (s * 10) % 11;
    if ((r == 10) || (r == 11)) r = 0;
    if (r != parseInt(c[9])) return false;
    s = 0;
    for (let i = 1; i <= 10; i++) s = s + parseInt(c[i-1]) * (12 - i); 
    r = (s * 10) % 11;
    if ((r == 10) || (r == 11)) r = 0;
    if (r != parseInt(c[10])) return false;
    return true;
}

export const isEmail = (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const isFullName = name => {
    const snps = name?.split(' ')
    return snps?.length > 1 && snps[0].length > 2 && snps[1].length > 2
}
 

export const GetCardType = (number) => {
    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null)
        return "Visa";

    // Mastercard 
    // Updated for Mastercard 2017 BINs expansion
     if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) 
        return "Master";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        return "Amex";

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
        return "Discover";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null)
        return "Diners";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null)
        return "Diners";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null)
        return "JCB";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null)
        return "Visa";

    // Elo
    if (/^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/.test(number) != null)
        return "Elo";

    // Auta
    if (/^(5078\d{2})(\d{2})(\d{11})$/.test(number) != null)
        return "Aura";
    
    // Hipercard
    if (/^(606282\d{10}(\d{3})?)|(3841\d{15})$/.test(number) != null)
        return "Hipercard";

    return "";
}




export const parseCurrency = (value) => {
    let values = `${(parseFloat(value)).toFixed(2)}`.split('.')
    return `R$ ${`${values[0]}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1.`)},${values[1]}`
}  

export const parseCurrencyMoney = (value) => {
    let values = `${(parseFloat(value)).toFixed(2)}`.split('.')
    return `${`${values[0]}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1.`)},${values[1]}`.split(',')[0]
}

export const parseCurrencyCents = (value) => {
    let values = `${(parseFloat(value)).toFixed(2)}`.split('.')
    return values[1]
} 