import React, { useContext, useEffect, useState } from "react";  
import { toast } from 'react-toastify';

import {    
    DashboardSpacer,
 
    PageSubtitle,
    PageText, 
    PageActions,
    BigInput, 


    PlanBanner,
    PlanContent,
    PlanContentInfo,
    PlanTitle,
    PlanAction,

    PlanCardContent,
    PlanBrand,
    PlanCardText

 
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 

import { CoreContext } from "context/CoreContext";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import Check from "components/Dashboard/Check";
import { CheckPlan } from "services/plans";
import { parseCurrency } from "utils";
import moment from "moment";
import 'moment/locale/pt-br';


export default function DashboardAccountPlan(){   
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext)

    const [loading, setLoading] = useState(false)
    const [currentPlan, setCurrentPlan] = useState(false)
    
    const save = () => {
        toast.success('Senha atualizada com sucesso') ;
        navigate('dashboard/account')
    }

    const init = async () => {
        setLoading(true)
        const result = await CheckPlan();
        if(result && result.recurrency && !result?.subscription?.canceled){
            setCurrentPlan(result)
        }
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
            <ContainerAuthenticated>  
                <Container>
                    <Row>
                        <Col md={{ size: 4, offset:4 }}>
                            <DashboardSpacer /> 
                            <PageSubtitle>Detalhes da assinatura</PageSubtitle>  

                            {/* <PlanBanner />  */}

                            {
                                currentPlan ? <>
                                    <PlanContent onClick={() => navigate('dashboard/account/change-plan')}>
                                        <PlanContentInfo>
                                            <PlanTitle>
                                                Hoxx Tv { currentPlan?.recurrency?.RecurrentPayment?.Interval === 'Annual' ? 'Anual' : 'Mensal' } <br />
                                                {  parseCurrency(currentPlan?.recurrency?.RecurrentPayment?.Amount / 100 ) }/{ currentPlan?.recurrency?.RecurrentPayment?.Interval === 'Annual' ? 'Ano' : 'Mês' }
                                            </PlanTitle>
                                        </PlanContentInfo>
                                        <PlanAction>
                                            Alterar
                                        </PlanAction>
                                    </PlanContent>
                                    <PlanCardContent onClick={() => navigate('dashboard/account/change-creditcard')}>
                                        <PlanContentInfo>
                                            <PlanBrand /> 
                                            <PlanCardText>
                                                { currentPlan?.subscription?.card_number }
                                            </PlanCardText>
                                        </PlanContentInfo>
                                        <PlanAction>
                                            Alterar
                                        </PlanAction>
                                    </PlanCardContent>


                                    <PageText>
                                        <big><b>Próxima data de cobrança</b></big>
                                        <p>{ moment(currentPlan?.recurrency?.RecurrentPayment?.NextRecurrency).format('LL') }</p>
                                    </PageText> 
                                    <PageText>
                                        <big><b>Último pagamento</b></big> 
                                        <p>{ moment(currentPlan?.recurrency?.RecurrentPayment?.StartDate).format('LL') }</p>
                                    </PageText> 
                                </> : null 
                            }


                            <PageActions> 
                                <Button primary onClick={() => history.goBack()}>Voltar</Button>
                                {
                                    !currentPlan ? null :
                                    <Button primary outline onClick={() => navigate('dashboard/account/cancel-subscription')}>Cancelar assinatura</Button>
                                }
                            </PageActions> 
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated> 
        </>
    );

}