import React, { useContext, useEffect, useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';
  

import { 
    BannerImage,
    BannerOut,
    BannerContent, 
    BannerCard, 
    BannerTitle, 
    ContentCardsPlan,
    ContentExperience,
    ContentActions,
    BannerImageExperience, 

    ContentExperienceDecoration,
    ContentExperienceText

} from "./styled"; 
import Button from "components/Button";
import { ReadPremiumPlans } from "services/authentication"; 
import { parsePlan } from "utils/parser";
import { CoreContext } from "context/CoreContext";
import { parseCurrency } from "utils";

export default function Experience(){

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { backgrounds } = useContext(CoreContext)

    const [plans, setPlans] = useState([])


    const init = async () => {
        const result = await ReadPremiumPlans()
        if(result?.plans?.length){
            setPlans(result.plans.map(item => parsePlan(item, backgrounds))) 
        }
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>  
            <BannerImage>
                <BannerOut />
                <BannerContent>
                    <Container>
                        <Row>
                            <Col md={{ size: 12 }}> 
                                <BannerCard>
                                    
                                    <BannerTitle> Uma experiência completa </BannerTitle>

                                    <BannerImageExperience />


                                    <ContentCardsPlan>

                                        {
                                            [
                                                { text:'Conteúdos de qualidade, selecionados para proporcionar a melhor experiência para sua família.'},
                                                { text:'Diversão garantia para toda família, temo em catálogo filmes, series e desenhos'},
                                                { text:'Temos como missão levar o entretenimento para dentro do seu lar, preservando os valores familiares.'},
                                            ].map((item, key) => 
                                                <ContentExperience key={key}>
                                                    <ContentExperienceDecoration />
                                                    <ContentExperienceText>
                                                        { item.text }
                                                    </ContentExperienceText>
                                                </ContentExperience>
                                            )
                                        }

                                        
                                    </ContentCardsPlan>
                                    
                                    <ContentActions>
                                        <Button secondary onClick={() => navigate('register')}>Assine agora</Button>
                                    </ContentActions>

                                </BannerCard>
                            </Col>
                        </Row>
                    </Container>
                </BannerContent>
            </BannerImage>
        </>
    );
}