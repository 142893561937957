import React, { useContext, useEffect, useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';
  

import { 
    BannerImage,
    BannerOut,
    BannerContent,
    BannerCenterContent,
    BannerLogo,
    BannerCenterActions,
    BannerCard,

    BannerTitle,
    BannerSubtitle,
    BannerText,
    BannerSubtext,

    LoginContent,
    ContentHeader,
    ContentHeaderIcon,


    ContentCardsPlan,
    CardPlan,
    CardPlanTitle,
    CardPlanPrice,
    CardPlanBenefitRow,
    CardPlanBenefitIcon,
    CardPlanBenefitText,
    CardPlanLabel,



    ContentNews,
    CardNews,
    CardNewsInfos,
    CardNewsInfosTitle,
    CardNewsInfosText,
    CardNewsImage


} from "./styled"; 
import Button from "components/Button";
import { ReadPremiumPlans } from "services/authentication"; 
import { parsePlan } from "utils/parser";
import { CoreContext } from "context/CoreContext";
import { parseCurrency } from "utils";

export default function Plans(){

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { backgrounds } = useContext(CoreContext)

    const [plans, setPlans] = useState([])


    const init = async () => {
        const result = await ReadPremiumPlans()
        if(result?.plans?.length){
            setPlans(result.plans.map(item => parsePlan(item, backgrounds))) 
        }
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>  
            <BannerImage>
                <BannerOut />
                <BannerContent>
                    <Container>
                        <Row>
                            <Col md={{ size: 12 }}> 
                                <BannerCard>



                                    
                                    <BannerTitle> Planos </BannerTitle>
                                    <ContentCardsPlan>
                                        {
                                            plans?.map((it, ky) => 
                                                    <CardPlan>
                                                        { ky === 1 ? <CardPlanLabel>MAIS POPULAR</CardPlanLabel> : null }
                                                        <CardPlanTitle>{ it?.title }</CardPlanTitle>
                                                        <CardPlanPrice>{ parseCurrency(it?.value || 0) }</CardPlanPrice>

                                                        {
                                                            [
                                                                { title:`Assista em até ${ ky === 1 ? 4 : 2 } telas ao mesmo tempo.` },
                                                                { title:"Qualidade em Full HD." },
                                                                { title:"Aproveite em todos os seus dispositivos." },
                                                                { title:"Chromecast disponível." }
                                                            ].map((item, key) => 
                                                                <CardPlanBenefitRow key={key}>
                                                                    <CardPlanBenefitIcon />
                                                                    <CardPlanBenefitText>{ item.title }</CardPlanBenefitText>
                                                                </CardPlanBenefitRow>
                                                            )
                                                        }

                                                        <Button secondary onClick={() => navigate('register')}>Assine agora o { it?.title }</Button>

                                                    </CardPlan>
                                            )
                                        }
                                    </ContentCardsPlan>
                                    <BannerSubtext> *Ao realizar a compra, todos os meses acontecerá a recorrência em seu cartão de crédito. </BannerSubtext>


                                    
                                    <ContentNews>
                                        <BannerTitle> Novidades chegando </BannerTitle>

                                        <CardNews>
                                            <CardNewsInfos>
                                                <CardNewsInfosTitle>Direito a vida</CardNewsInfosTitle>
                                                <CardNewsInfosText>Quando a jovem Alison descobre que está grávida do namorado irresponsável, ela decide abortar. Na clínica, enquanto espera ser chamada, depara-se com um grupo de personagens cômicos e excêntricos: uma conselheira extravagante, uma típica mãe do subúrbio, uma recepcionista apática e Lecretia, uma garota do interior cujos pensamentos são expressos de maneira peculiar.</CardNewsInfosText>
                                                <Button secondary onClick={() => navigate('register')}>Assine agora</Button>
                                            </CardNewsInfos>
                                            <CardNewsImage />
                                        </CardNews>

                                    </ContentNews>

                                    





                                </BannerCard>
                            </Col>
                        </Row>
                    </Container>
                </BannerContent>
            </BannerImage>
        </>
    );
}