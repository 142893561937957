import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';
  

import { 
    BannerImage,
    BannerOut,
    BannerContent,
    BannerCenterContent,
    BannerLogo,
    BannerCenterActions,
    BannerCard,

    BannerTitle,
    BannerSubtitle,
    BannerText,
    BannerSubtext,

    LoginContent,
    ContentHeader,
    ContentHeaderIcon
} from "./styled"; 
import Button from "components/Button";

export default function Banner({ onlyHeader }){

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    return ( 
        <> 
            <ContentHeader>
                <ContentHeaderIcon onClick={() => navigate('')} />
            </ContentHeader>
            {
                onlyHeader ? null :
                <BannerImage>
                    <BannerOut />
                    <BannerContent>
                        <Container>
                            <Row>
                                <Col md={{ size: 12 }}> 
                                    <BannerCard>
                                        
                                        <BannerTitle> A diversão para sua família </BannerTitle>
                                        <BannerSubtitle>Está aqui!</BannerSubtitle>
                                        <BannerSubtext>Os melhores conteúdos cristão e educativos reunidos em um só lugar.</BannerSubtext>
                                        <BannerSubtext>&nbsp;</BannerSubtext>
                                        <BannerSubtext>Filmes, séries, e área kids com muitos desenhos.</BannerSubtext>
                                        <BannerCenterActions>
                                            <Button secondary onClick={() => navigate('login')}>Entrar</Button>
                                        </BannerCenterActions>
                                    </BannerCard>
                                </Col>
                            </Row>
                        </Container>
                    </BannerContent>
                </BannerImage>
            }
        </>
    );
}