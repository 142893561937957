import React, { useContext, useEffect, useRef, useState } from "react";  

import {    

} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 
import DashboardRowVideos from "components/Dashboard/RowVideos";
import DashboardMenu from "components/Dashboard/Menu";
import CarouselBanner from "components/Dashboard/CarouselBanner";
import { availableCatagories, availableVideos } from "utils/mock";
import { CoreContext } from "context/CoreContext";
import { ReadHome, ReadHomeTabs, ReadKeepWatching, ReadTabCatalog, ReadTabPlaylist } from "services/catalog";
import { parseCollection, parseVideo } from "utils/parser";
import { Load, CenterLoad, SpacerMore } from "ui/styled";
import { normalizeStrapiRegister } from "utils";

export default function DashboardHome(){   

    const { currentCategory, currentProfile } = useContext(CoreContext)

    const [banners, setBanners] = useState([])
    const [tabs, setTabs] = useState([])
    const [loading, setLoading] = useState(false)
    const [begining, setBegining] = useState(true)
    const [categoryCollections, setCategoryCollections] = useState([])

    const scrollRef = useRef();

    const init = async () => {
        const home = await ReadHome()
        const tabs = await ReadHomeTabs()

        if(home?.data?.attributes?.featured?.length){
            setBanners(home?.data?.attributes?.featured)
        } 

        // console.log("currentProfile", currentProfile)

        if(tabs?.data?.length){
            setTabs((currentProfile?.kids) ? [...tabs?.data].filter(f => f.attributes.name === "Kids") : [...tabs?.data])
        } 
    }

    const initCategory = async () => {
        if((currentCategory > 0 && currentCategory < 4) && !tabs[currentCategory-1]?.id){ return ;}
        if(loading) return;
        console.log("INIT CAT")
        setLoading(true)
        const requests = []
        if(currentCategory === 0){
            requests.push(ReadTabPlaylist())
        }
        if(currentCategory > 0 && currentCategory < 4){
            if(tabs[currentCategory-1]?.id){
                requests.push(ReadTabCatalog(tabs[currentCategory-1]?.id))
            }
        }
        if(currentCategory === 4){
            // showing more, nothing to do
        }
        let result =  await Promise.all(requests)

        if(currentProfile?.kids){
            result[0].data = result?.[0]?.data?.filter(f => f?.attributes?.name === "Kids")
        }

        console.log("result, ", result)

        if(result?.[0]?.data?.length){

            const resultWatch = await ReadKeepWatching(currentProfile?.id)

            // console.log( "new Date(a?.attributes?.updatedAt)", new Date(resultWatch?.data?.[0]?.attributes?.updatedAt).getTime() )

            const videosWatching = (resultWatch?.data||[])
            ?.sort((a, b) => new Date(b?.attributes?.updatedAt).getTime() - new Date(a?.attributes?.updatedAt).getTime()  )
                                        ?.map(mm => {
                                            const normalRegister = normalizeStrapiRegister(mm)
                                            // console.log("normalRegister", normalRegister)
                                            if(!normalRegister?.stream_show || !!normalRegister?.data?.finishing) return false;
                                            return parseVideo(normalRegister?.stream_show);
                                        })
                                        ?.filter(f => f)
                                        ?.reduce((p, c) => p.map(m => m.id)?.includes(c.id) ? p : [...p, c], [])

            console.log("videosWatching", videosWatching, resultWatch)

            const watched = {
                id: 2,
                image: null,
                title: "Continuar assistindo",
                videos: videosWatching || []
            }

            console.log('Collections', result?.[0]?.data, result?.[0]?.data?.map(parseCollection))
            setCategoryCollections(watched?.videos?.length && currentCategory === 0 ? [
                watched,
                ...result?.[0]?.data?.map(parseCollection)
            ]: result?.[0]?.data?.map(parseCollection)) 

        }else{
            setCategoryCollections([])
        }
        setLoading(false)
        setBegining(false)
    }
 
    useEffect(() => {
        if(tabs?.length && currentProfile){ initCategory() }
    }, [currentCategory, tabs, currentProfile])
     
    useEffect(() => {
        if(currentProfile){ init() ;}
    }, [currentProfile])


    // Load More, slice strategy
    const pageSize = 2;
    const [page, setPage] = useState(1)
    const [loadingMore, setLoadingMore] = useState(false)

    let timerMore = useRef().current
    
    useEffect(() => {
        const currentRef = scrollRef
        const handleScroll = () => { 
            console.log("HANDLE SCROLL")
            if( 
                (( currentRef?.current && currentRef?.current?.scrollTop === (currentRef?.current?.scrollHeight - currentRef?.current?.clientHeight) ))
                && (categoryCollections?.length >= (pageSize*(page)))
            ){
                clearTimeout(timerMore)
                setLoadingMore(true)
                console.log("Handle More")
                timerMore = setTimeout(() => {
                    setPage( page + 1 )
                    setLoadingMore(false)
                }, 1500)
            } else {
                // console.log("why", wrapper , currentRef?.current , currentRef?.current?.scrollTop , (currentRef?.current?.scrollHeight - currentRef?.current?.clientHeight))
            }

        }   
        if(currentRef?.current){
            currentRef?.current?.addEventListener("scroll", handleScroll)
            // console.log("REFed")
            return () => {
                currentRef?.current?.removeEventListener("scroll", handleScroll)
                clearTimeout(timerMore)
            }
        }else{
            // console.log("no REF")
        }
        return () => {
            clearTimeout(timerMore)
        }
    }, [page, categoryCollections])

    return ( 
        <>
            <ContainerAuthenticated scrollRef={scrollRef} primary page={'home'}>  

                <CarouselBanner banners={banners}  />
                {
                    begining ? null :
                    <DashboardMenu tabs={tabs}  /> 
                }


                { loading ? 
                    <CenterLoad>
                        <Load white /> 
                    </CenterLoad>
                : null }

                { 
                    categoryCollections?.length ? <>
                        {
                            categoryCollections?.slice(0, (pageSize*page) )?.map((cat, kcat) => 
                                <DashboardRowVideos key={kcat} { ...cat } wrapper={categoryCollections?.length === 1} parentScrollRef={scrollRef} /> 
                            )
                        }
                    </> : null
                }


                { loadingMore ? 
                    <CenterLoad>
                        <Load big white /> 
                    </CenterLoad>
                : null }

                <SpacerMore />
                
            </ContainerAuthenticated> 
        </>
    );

}