import React, { useEffect, useState } from "react"; 
 

import ContainerLandpage from "containers/Landpage";

import Banner from "components/Landpage/Banner"; 

import Plans from "components/Landpage/Plans";
import Catalog from "components/Landpage/Catalog";
import Experience from "components/Landpage/Experience";
import CatalogPopular from "components/Landpage/CatalogPopular";
import TalkingAboutUs from "components/Landpage/TalkingAboutUs";
import FAQ from "components/Landpage/FAQ";
import Footer from "components/Landpage/Footer";


import { ReadHome, ReadHomeTabs, ReadKeepWatching, ReadTabCatalog, ReadTabPlaylist } from "services/catalog";
import { parseCollection } from "utils/parser";

export default function Landpage(){ 

    const [loading, setLoading] = useState(true)
    const [categoryCollections, setCategoryCollections] = useState([])
 
    const init = async () => { 
        setLoading(true)
        const result =  await ReadTabPlaylist()
        if(result?.data?.length){ 
            setCategoryCollections(result?.data?.map(parseCollection)) 
        }else{
            setCategoryCollections([])
        }
        setLoading(false)
    } 
    
    useEffect(() => { init() ;}, [])

    return ( 
        <ContainerLandpage> 

                <Banner />  
                <Plans />
                <Catalog collection={categoryCollections} />
                <Experience />
                <CatalogPopular collection={categoryCollections} />
                <TalkingAboutUs />
                <FAQ />

                <Footer />
                
        </ContainerLandpage>
    );
}