import styled from 'styled-components' 

export const BannerImage = styled.div.attrs({ 
})`           

    position: relative;
    min-height: calc(100vh);
    width: 100%;

    background: var(--primary-color) url(/images/tv-background.png) no-repeat center center / cover;
`; 

export const BannerOut = styled.div.attrs({ 
})`            
    min-height: calc(100vh);
    height: 100%;
    width: 100%;

    background: var(--darkshadow-color); 
    mix-blend-mode: multiply;
    position: absolute;
    z-index:1;
`; 

const degradeeColor = "93,0,0"
// const degradeeColor = "249,94,20"

export const BannerContent = styled.div.attrs({ 
})`           
    min-height: calc(100vh);
    width: 100%; 

    background: -moz-linear-gradient(180deg, rgba(${ degradeeColor },.7) 0%, rgba(83,83,83,.7) 80%);
    background: -webkit-linear-gradient(180deg, rgba(${ degradeeColor },.7) 0%, rgba(83,83,83,.7) 80%);
    background: linear-gradient(180deg, rgba(${ degradeeColor },.7) 0%, rgba(83,83,83,.7) 80%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(${ degradeeColor },.4)",endColorstr="rgba(83,83,83,.4)",GradientType=1);


    position:relative;
    z-index:2;
`; 

export const BannerCard = styled.div.attrs({ 
})`        
    // max-width: 920px;
    margin: 0 auto;
    padding: 30px 0;
`; 

export const BannerImageExperience = styled.div.attrs({ 
})`        
    width: 100%;
    height: 56vh;

    background: url(/images/hoxx-experience.png) no-repeat center center / contain ;
    opacity: .9;

    margin: 40px 0 ;
`; 



export const BannerTitle = styled.h1.attrs({ 
})`       
    font-size: 28px;
    color: var(--white-color);
    font-weight: bold;
    margin: 0 0 30px;
`;  

export const ContentCardsPlan = styled.div.attrs({ 
})`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: 10px;
`; 

export const ContentExperience = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 385px;
    border-radius: 10px;
    background: var(--darkshadow-color); 
    padding: 32px 24px;
`; 


export const ContentActions = styled.div.attrs({ 
})`
    max-width: 280px;
    margin: 0 auto;
    padding-top: 1px;
`; 








export const ContentExperienceDecoration = styled.div.attrs({ 
})`
    height: 4px;
    width: 70%;
    border-radius: 2px;
    background: var(--primary-color);
    margin: 0 auto 24px;
`; 
export const ContentExperienceText = styled.div.attrs({ 
})`
    font-size: 18px;
    font-weight: 100;
    color: var(--white-color);
    opacity: .8;
`; 
