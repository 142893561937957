import styled from 'styled-components'  
 
export const FormContent = styled.div.attrs({ 
})`           
    min-height:100vh;
    padding: 0;
`; 
export const Content = styled.div.attrs({ 
})`           
    background: var(--white-color);
    ${
        props => props.image ? `
            background: url(${ props.image }) no-repeat center center / cover;
            background: var(--darkprimary-color);
        ` : ``
    }
    ${
        props => props.primary ? `
            background: var(--darkprimary-color);
        ` : ``
    }
`;  

export const Banner = styled.div.attrs({ 
})`           
    width: 100%;
    height: 180px;
    ${
        props => props.image ? `
            background: var(--darkprimary-color) url(${ props.image }) no-repeat center center / contain;
        ` : ``
    }
`;