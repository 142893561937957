import styled from 'styled-components' 



export const BannerContent = styled.div.attrs({ 
})`           
    width: 100%; 
 

    background: #000;

    position:relative;
    z-index:2;
`; 

export const BannerCard = styled.div.attrs({ 
})`        
    // max-width: 920px;
    margin: 0 auto;
    padding: 30px 0 0;
`; 



export const BannerTitle = styled.h1.attrs({ 
})`       
    font-size: 28px;
    color: var(--white-color);
    font-weight: bold;
    margin: 0 0 30px;
`;  

export const ContentCardsPlan = styled.div.attrs({ 
})`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: 30px;
    padding: 20px 0;
`; 


export const ContentActions = styled.div.attrs({ 
})`
    max-width: 280px;
    margin: 0 auto;
    padding-top: 1px;
`; 





export const ViewPreview = styled.div.attrs({ 
})`
    width: 260px; 
    border-radius: 10px;
    background: var(--white-color);  
    padding: 16px;
    position:relative;
`; 


export const RewviewDecoration = styled.div.attrs({ 
})`
    width: 56px;
    height: 18px;
    border-radius: 16px;

    background: -moz-linear-gradient(180deg, rgba(93,93,93,1) 0%, rgba(0,0,0,1) 160%);
    background: -webkit-linear-gradient(180deg, rgba(93,93,93,1) 0%, rgba(0,0,0,1) 160%);
    background: linear-gradient(180deg, rgba(93,93,93,1) 0%, rgba(0,0,0,1) 160%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(93,93,93,1)",endColorstr="rgba(0,0,0,1)",GradientType=1);

    position: absolute;
    top: -10px;

`; 
export const ReviewTitle = styled.div.attrs({ 
})`
    font-size: 18px;
    font-weight: bold;
    color: var(--black-color);
`; 
export const ReviewText = styled.div.attrs({ 
})`
    font-size: 16px; 
    color: var(--black-color);
`; 
export const ReviewRate = styled.div.attrs({ 
})`
    display: flex;
    padding: 12px 0;
`; 
export const ReviewRateIcon = styled.img.attrs({ 
    src:"/icons/star-black.svg"
})`

`; 

