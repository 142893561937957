import { CoreContext } from "context/CoreContext";
import React, { useContext, useState } from "react";   
import { useHistory } from "react-router-dom";
import { DoLogin, DoLogout } from "services/authentication";
  
import {   
    
    HeaderMenu,
    HeaderMenuImage,
    HeaderMenuIcon,


    HeaderMenuContent,
    HeaderMenuContentSection,
    HeaderMenuUser,

    HeaderMenuUserImage,
    HeaderMenuOption,

    ContentAllowWithPassword,
    ContentAllowWithPasswordContent,
    ContentAllowWithPasswordContentInput,
    ContentAllowWithPasswordContentActions
} from "./styled"; 
import Input from "components/Input";
import Button from "components/Button";
import { exposeStrapiError } from "utils";

export default function DashboardHeaderMenu(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);
    
    const [ needLogin, setNeedLogin ] = useState(false) 
    const [ allowed, setAllowed ] = useState(false) 
    const [ loading, setLoading ] = useState(false) 
    const [ menuOpened, setMenuOpened ] = useState(false) 
    const [ password, setPassword ] = useState('') 
    const { user, setCurrentProfile, currentProfile, profiles, setProfiles } = useContext(CoreContext)

    const selectProfile = item => {
        setCurrentProfile(item)
        setMenuOpened(false)
    } 

    const exit = async () => {
        setProfiles([])
        setCurrentProfile(null)

        await DoLogout()
        navigate('login')
    } 

    const checkOpen = () => {
        if( currentProfile?.kids && !allowed){
            setNeedLogin(true)
            return;
        }
        setMenuOpened(!menuOpened)
    }

    const checkPassword = async () => {
        if(!loading){
            setLoading(true)
            const result = await DoLogin({ password, identifier: user?.email?.replace(/ /g,'') })  
            setLoading(false)
            if(result && !exposeStrapiError(result)){
                setNeedLogin(false)
                setAllowed(true)
                setMenuOpened(true)
            } 
        }
    }


    return ( 
        <>   
                <HeaderMenu onClick={() => checkOpen()}>
                    { currentProfile?.name ? currentProfile?.name : 'Menu' }
                    <HeaderMenuImage image={ currentProfile?.avatar?.image ? currentProfile?.avatar?.image : '/images/users/face.png' } />
                    <HeaderMenuIcon /> 
                </HeaderMenu>
                {
                    menuOpened ?
                        <HeaderMenuContent>
                            <HeaderMenuContentSection> 
                                {
                                    profiles?.filter(fit => fit.id !== currentProfile.id)?.slice(0,2).map((item, key) => 
                                        <HeaderMenuUser key={key} onClick={() => selectProfile(item)}>
                                            <HeaderMenuUserImage image={item.avatar?.image} /> 
                                            { item.name }
                                        </HeaderMenuUser>
                                    )
                                } 
                                <HeaderMenuOption onClick={() => navigate('dashboard/select-profile')}>Gerenciar perfis</HeaderMenuOption>
                            </HeaderMenuContentSection>
                            <HeaderMenuContentSection white>
                                <HeaderMenuOption onClick={() => navigate('dashboard/account')}>Minha conta</HeaderMenuOption>
                                <HeaderMenuOption onClick={() => navigate('dashboard/help')}>Ajuda</HeaderMenuOption>
                                <HeaderMenuOption onClick={() => navigate('dashboard/about')}>Sobre</HeaderMenuOption>
                                <HeaderMenuOption onClick={() => exit() }>Sair</HeaderMenuOption>
                            </HeaderMenuContentSection>
                        </HeaderMenuContent>
                    : null
                } 

                {
                    !needLogin ? null :
                        <ContentAllowWithPassword>
                            <ContentAllowWithPasswordContent>
                                    Informe a senha para desbloquear
                                    <ContentAllowWithPasswordContentInput>
                                        <Input placeholder="Senha" type="password" value={password} onChange={e => setPassword(e.target.value)} />   
                                    </ContentAllowWithPasswordContentInput>
                                    <ContentAllowWithPasswordContentActions>
                                        <Button onClick={() => setNeedLogin(false)}>Cancelar</Button>
                                        <Button primary loading={loading} onClick={checkPassword}>Desbloquear</Button>
                                    </ContentAllowWithPasswordContentActions>
                            </ContentAllowWithPasswordContent>
                        </ContentAllowWithPassword>
                }
        </>
    );
}

