import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Landpage from 'screens/Landpage' 
import NotFound from 'screens/NotFound' 

import Login from 'screens/Authentication/Login' 
import Register from 'screens/Authentication/Register' 
import Forgot from 'screens/Authentication/Forgot' 
import CreatePassword from 'screens/Authentication/CreatePassword' 

import DashboardSelectProfile from 'screens/Dashboard/SelectProfile' 
import DashboardCreateProfile from 'screens/Dashboard/CreateProfile' 
import DashboardHome from 'screens/Dashboard/Home' 
import DashboardFavorites from 'screens/Dashboard/Favorites' 
import DashboardCategory from 'screens/Dashboard/Category' 
import DashboardSearch from 'screens/Dashboard/Search' 

import DashboardAbout from 'screens/Dashboard/About' 
import DashboardHelp from 'screens/Dashboard/Help' 
import DashboardSign from 'screens/Dashboard/Sign' 

import DashboardAccount from 'screens/Dashboard/Account' 
import DashboardAccountConfirmEmail from 'screens/Dashboard/Account/ConfirmEmail' 
import DashboardAccountChangeEmail from 'screens/Dashboard/Account/ChangeEmail' 
import DashboardAccountCreatePassword from 'screens/Dashboard/Account/CreatePassword' 
import DashboardAccountPlan from 'screens/Dashboard/Account/Plan' 
import DashboardAccountChangePlan from 'screens/Dashboard/Account/ChangePlan' 
import DashboardAccountCancelSubscription from 'screens/Dashboard/Account/CancelSubscription' 
import DashboardAccountChangeCreditCard from 'screens/Dashboard/Account/ChangeCreditCard' 

import DashboardPlayer from 'screens/Dashboard/Player' 
import DashboardPrivacityPolice from "screens/PrivacityPolice";
import DashboardLimit from "screens/Dashboard/Limit";
import DashboardRequestAccountTerminate from "screens/RequestAccountTerminate";

export default function AppRouter() {
    return (
      <Router>  
        <div>
          <Switch>
            <Route path="/" exact> <Landpage /> </Route> 
            
            <Route path="/login" exact> <Login /> </Route> 
            <Route path="/register" exact> <Register /> </Route> 
            <Route path="/forgot" exact> <Forgot /> </Route> 
            <Route path="/create-password" exact> <CreatePassword /> </Route> 
            
            <Route path="/dashboard" exact> <DashboardHome /> </Route> 
            <Route path="/dashboard/select-profile" exact> <DashboardSelectProfile /> </Route> 
            <Route path="/dashboard/create-profile" exact> <DashboardCreateProfile /> </Route> 
            <Route path="/dashboard/favorites" exact> <DashboardFavorites /> </Route> 
            <Route path="/dashboard/category/:slug" exact> <DashboardCategory /> </Route> 
            <Route path="/dashboard/search" exact> <DashboardSearch /> </Route> 
            
            <Route path="/dashboard/about" exact> <DashboardAbout /> </Route> 
            <Route path="/dashboard/help" exact> <DashboardHelp /> </Route> 
            <Route path="/dashboard/sign" exact> <DashboardSign /> </Route> 
            <Route path="/dashboard/limit" exact> <DashboardLimit /> </Route> 

            <Route path="/dashboard/account" exact> <DashboardAccount /> </Route> 
            <Route path="/dashboard/account/confirm-email" exact> <DashboardAccountConfirmEmail /> </Route> 
            <Route path="/dashboard/account/change-email" exact> <DashboardAccountChangeEmail /> </Route> 
            <Route path="/dashboard/account/create-password" exact> <DashboardAccountCreatePassword /> </Route> 
            <Route path="/dashboard/account/plan" exact> <DashboardAccountPlan /> </Route> 
            <Route path="/dashboard/account/change-plan" exact> <DashboardAccountChangePlan /> </Route> 
            <Route path="/dashboard/account/cancel-subscription" exact> <DashboardAccountCancelSubscription /> </Route> 
            <Route path="/dashboard/account/change-creditcard" exact> <DashboardAccountChangeCreditCard /> </Route> 
            <Route path="/dashboard/player" exact> <DashboardPlayer /> </Route> 
            <Route path="/dashboard/player/:video_id" exact> <DashboardPlayer /> </Route> 
            <Route path="/dashboard/player/:video_id/:running" exact> <DashboardPlayer /> </Route> 
            <Route path="/privacity-police" exact> <DashboardPrivacityPolice /> </Route> 
            <Route path="/request-account-terminate" exact> <DashboardRequestAccountTerminate /> </Route> 
            
            <Route path="*" exact> <NotFound /> </Route>
          </Switch>
        </div>
      </Router>
    );
}
