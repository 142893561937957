import React, { useCallback, useEffect, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import {   

    VideosContent,
    VideosContentTitle,
    VideosContentRow, 
    VideosContentCircle,
    VideosContentCircleSpace,
    VideosContentCircleIcon, 
    VideoContentVideo,
    VideoContentVideoDecoration,
    VideosContentRowScroll,
    VideoContentVideoText,
    VideoContentVideoContainer

} from "./styled";  
import { Load } from "ui/styled";

export default function DashboardRowVideos({ title, icon, image, videos, wrapper, parentScrollRef }){  
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const openVideo = (mit) => {
        navigate(`dashboard/player/${mit.id}`)
    }

    // Load More, slice strategy
    const scrollRef = useRef()
    const pageSize = 8;
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)

    let timerMore = useRef().current
    
    useEffect(() => {
        const currentRef = !!wrapper ? parentScrollRef : scrollRef
        const handleScroll = () => { 
            console.log("HANDLE SCROLL")
            if( 
                (( !wrapper && currentRef?.current && currentRef?.current?.scrollLeft === (currentRef?.current?.scrollWidth - currentRef?.current?.clientWidth) )
                || ( wrapper && currentRef?.current && currentRef?.current?.scrollTop === (currentRef?.current?.scrollHeight - currentRef?.current?.clientHeight) ))
                && (videos?.length >= (pageSize*(page)))
            ){
                clearTimeout(timerMore)
                setLoading(true)
                console.log("Handle More")
                timerMore = setTimeout(() => {
                    setPage( page + 1 )
                    setLoading(false)
                }, 1500)
            } else {
                // console.log("why", wrapper , currentRef?.current , currentRef?.current?.scrollTop , (currentRef?.current?.scrollHeight - currentRef?.current?.clientHeight))
            }

        }   
        if(currentRef?.current){
            currentRef?.current?.addEventListener("scroll", handleScroll)
            // console.log("REFed")
            return () => {
                currentRef?.current?.removeEventListener("scroll", handleScroll)
                clearTimeout(timerMore)
            }
        }else{
            // console.log("no REF")
        }
        return () => {
            clearTimeout(timerMore)
        }
    }, [page, videos]) 

    return ( 
        <> 
            <VideosContent>
                <VideosContentTitle>{ title }</VideosContentTitle>
                <VideosContentRowScroll ref={scrollRef}>
                    <VideosContentRow gap={!icon} wrapper={wrapper}>
                        {
                            (icon || image) ? <>
                                <VideosContentCircle image={image}>
                                    { icon ? <VideosContentCircleIcon icon={icon}/> : null }
                                </VideosContentCircle>
                            </> : wrapper ? null : <VideosContentCircleSpace />
                        }
                        {
                            videos?.slice(0, (pageSize*page) ).map((mit, mik) => <VideoContentVideoContainer key={mik} >
                                    <VideoContentVideo image={mit.cover} onClick={() => openVideo(mit)}>
                                        <VideoContentVideoDecoration />
                                    </VideoContentVideo>
                                    <VideoContentVideoText>{ mit.title }</VideoContentVideoText>
                                </VideoContentVideoContainer>
                            )
                        }
                        {
                            !loading ? null :
                            <VideoContentVideoContainer wrapper={wrapper}>
                                <VideoContentVideo centred wrapper={wrapper}>
                                    <Load big  />
                                </VideoContentVideo>
                                <VideoContentVideoText>&nbsp;</VideoContentVideoText>
                            </VideoContentVideoContainer>
                        }
                    </VideosContentRow>
                </VideosContentRowScroll>
            </VideosContent> 
        </>
    );
}