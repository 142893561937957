import styled from 'styled-components'  
 
export const LoginSpacer = styled.div.attrs({ 
})`            
`;
 
export const CentredContent = styled.div.attrs({ 
})`  
    padding-top: 215px;
    max-width: 325px;      
    margin: 0 auto;
    ${
        props => props.big ? `
            padding-top: 310px;
        ` : ``
    }
`;

export const WhiteCard = styled.div.attrs({ 
})`           
    padding: 16px 12px 25px;
    background: var(--whiteshadow-color);
    border-radius: 10px;
`;
 
export const CardTitle = styled.div.attrs({ 
})`           
    color: var(--white-color);
    font-size: 18px;
    font-weight: bold;
    margin: 0 10px;

    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;
 
export const CardText = styled.div.attrs({ 
})`           
    color: var(--white-color);
    font-size: 14px;
    text-align: center;
`;
 



export const WrapUsers = styled.div.attrs({ 
})`
    justify-content: center;   
    padding: 20px 0;
    display: flex;  
    gap: 0 25px;
`;


export const UserItem = styled.div.attrs({ 
})`       
    width: 76px;
    height: 76px;
    border-radius: 38px;
    position: relative;

    ${
        props => props.image ? `
            background: url(${ props.image }) no-repeat center center / cover;
        ` : `
            background: var(--primary-color) url(/images/users/smile.png) no-repeat center center / cover;
            border: 5px solid var(--secondary-color);
            &:after{
                content:"+"; 
                width: 24px;
                height: 24px;
                border-radius: 12px;
                background: var(--secondary-color);
        
                display: flex;
                align-items: center;
                justify-content: center;
        
                position: absolute;
                bottom: -3px;
                right: -6px;
        
                color: var(--white-color);
                font-size: 18px;
                font-weight: bold;
            }
        `
    }

    cursor:pointer;
    &:hover{

    }
`;
  
export const UserTitle = styled.div.attrs({ 
})`           
    color: var(--primary-color);
    font-size: 30px;
    font-weight: bold; 
    text-align: center;
`; 
  
export const UserSelected = styled.div.attrs({ 
})`           
    width: 112px;
    height: 112px;
    border-radius: 56px;

    margin: 7px auto;

    background: url(${ props => props.image }) no-repeat center center / cover;
`; 