import React, { useContext, useEffect, useState } from "react"; 
import { useHistory } from 'react-router-dom'; 
import { toast } from 'react-toastify';

import Input from 'components/Input'

import { 
    FormContent,
    FormTitle,
    FormText,
    FormLabel,
    FormTouch,
    FormSpacer,
    
    PlanContent,
    PlanImage,
    PlanText,

    SelectedPlan,
    SelectedPlanTitle,
    SelectedPlanText,

    FormWrap,
    LicenseText,

    TabsContent,
    TabItem,

    WhiteCard,
    CardBanner,
    CardTitle,
    CardText

} from './styled'

import Button from "components/Button";
import SelectInstallment from "components/SelectInstallment";

import ContainerAuthenticated from "containers/Authenticated";

import { CoreContext } from "context/CoreContext";
import { ReadPremiumPlans } from "services/authentication"; 

import { exposeStrapiError, isEmail, parseCurrency } from "utils"; 
import { parsePlan, parseProfile } from "utils/parser";
import { SubscribePlan } from "services/plans";
import { GetCardType } from "utils";

export default function DashboardSign(){ 

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { setUser, setProfiles, setCurrentProfile, backgrounds } = useContext(CoreContext)

    const [ availablePlans, setAvailablePlans ] = useState([])
    const [ loading, setLoading ] = useState(false) 
    const [ success, setSuccess ] = useState(false) 
    const [ step, setStep ] = useState(0) 

    const steps = [ 
        { title:'Escolha seu plano:' },
        // { title:'Insira sua forma de pagamento' }  
    ]
    
    const [ form, setForm ] = useState({
        paymentMethod: 0
    })
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const valid = (verbose = false) => {  

        if(step === 1){  

            if(formValue('paymentMethod') == 1){
                // invoice
                if(!formValue('document') || !formValue('document').length){ 
                    if(verbose){ toast.error('Preencha o campo: CPF') ;}
                    return false; 
                }
            }

            if(formValue('paymentMethod') == 0){
                // credit card
                if(!formValue('card_name') || !formValue('card_name').length){ 
                    if(verbose){ toast.error('Preencha o campo: Nome') ;}
                    return false; 
                }
                if(!formValue('card_number') || !formValue('card_number').length){ 
                    if(verbose){ toast.error('Preencha o campo: Número do cartão') ;}
                    return false; 
                }
                if(!formValue('card_expires') || !formValue('card_expires').length || formValue('card_expires')?.replace(/\ /g, '')?.length < 7){ 
                    if(verbose){ toast.error('Preencha o campo: Validade') ;}
                    return false; 
                }
                if(!formValue('card_cvc') || !formValue('card_cvc').length){ 
                    if(verbose){ toast.error('Preencha o campo: Cod. Segurança') ;}
                    return false; 
                }
                // if(!formValue('card_installment') || !formValue('card_installment').length){ 
                //     if(verbose){ toast.error('Selecione o parcelamento') ;}
                //     return false; 
                // }
                // if(!formValue('card_document_user') || !formValue('card_document_user').length){ 
                //     if(verbose){ toast.error('Preencha o campo: CPF ou CNPJ do Titular') ;}
                //     return false; 
                // }
            }
        }   
        return true
    } 

    const action = () => {
        if(!valid(true)){ return ;}
        if((step+1)<steps.length){
            setStep(step+1)
        }else{
            actionLogin()
        }
    }

    const back = () => {
        if((step-1)>=0){
            setStep(step-1)
        }else{
            // go back page?
        }
    }

    const showSuccess = () => {
        setSuccess({
            title:'Parabéns!!',
            text:'Compra Finalizada com Sucesso',
            background: backgrounds?.find(fnd => fnd.locale === 'success_purchase')?.url ,
            banner: backgrounds?.find(fnd => fnd.locale === 'success_purchase_header')?.url,
            primary:{
                action: () => start(),
                label: 'Começar'
            }, 
        });
    }

    const showRetry = () => {
        setSuccess({
            title:'Ops! =(',
            text:'Verifique com seu banco. Pagamento não efetuado',
            background: backgrounds?.find(fnd => fnd.locale === 'failed_purchase')?.url ,
            banner: backgrounds?.find(fnd => fnd.locale === 'failed_purchase_header')?.url,
            primary:{
                action: () => signPlan(),
                label: 'Tentar novamente'
            }
        });
    }

    const showInvoiced = () => {
        setSuccess({
            title:'Quase lá!',
            text:'Faça o download do PDF ou Copie o código de barra, que aguardaremos a confirmação de pagamento. Por segurança enviamos o Boleto no seu e-mail.',
            background: backgrounds?.find(fnd => fnd.locale === 'success_purchase')?.url ,
            banner: backgrounds?.find(fnd => fnd.locale === 'success_purchase_header')?.url,
            big: true,
            primary:{
                action: () => null,
                label: 'Download do PDF'
            },
            secondary:{
                action: () => copyInvoice(),
                label: 'Copiar código de Barra'
            },
            outline:{
                action: () => start(),
                label: 'Começar'
            },
        });
    }

    const actionLogin = async () => { 
        signPlan()
    }

    const signPlan = async () => { 

        setLoading(true)
        const CardNumber = formValue('card_number')?.replace(/\ /g, '')
        const Brand = GetCardType(CardNumber)
        const payload = {
            data:{
                credit_card: {
                    CardNumber,
                    Holder: formValue('card_name'),
                    ExpirationDate: formValue('card_expires')?.replace(/\ /g, ''),
                    SecurityCode: formValue('card_cvc')?.replace(/\ /g, ''),
                    Brand
                },
                plan: formValue('plan')?.id
            }
        } 
        const result = await SubscribePlan(payload)
        
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            showSuccess()
        } else{
            showRetry()
        }
    }

    const copyInvoice = () => {
        toast.info('Copiado'); 
    }    

    const start = async () => { 
        history.goBack()
    }

    const selectPlan = item => {    
        if(item?.checkout_sun_url){
            window.open(item?.checkout_sun_url, "_new") 
            navigate("dashboard")
        }
        // changeForm(item, 'plan')
        // action()
    }

    const paymentOptions = [
        { title: 'Cartão de crédito' },
        { title: 'Boleto' },
    ]

    const installmentOptions = () => {
        const fullValue = formValue('plan')?.value
        return fullValue ? [1,2,3,4,5,6,7,8,9,10].map(installments => ({ value: parseFloat(fullValue/installments).toFixed(2), installments })) : []
    }  

    const init = async () => {
        const result = await ReadPremiumPlans()
        if(result?.plans?.length){
            setAvailablePlans(result.plans.map(item => parsePlan(item, backgrounds))) 
        }
    }

    useEffect(() => {
        init()
    }, [])
 
    return ( 
        <>  
            {
                success ? <>
                    <ContainerAuthenticated image={success.background}> 
                        <FormContent>
                            <WhiteCard>
                                <CardBanner image={ success.banner } />
                                <CardTitle>{ success.title }</CardTitle>
                                <CardText big={success.big}>{ success.text }</CardText>
                            </WhiteCard>
                            { success.primary ? <Button primary onClick={success.primary.action}>{ success.primary.label }</Button> : null }
                            { success.secondary ? <Button secondary onClick={success.secondary.action}>{ success.secondary.label }</Button> : null }
                            { 
                                success.outline ? <>
                                    <FormSpacer  /> 
                                    <Button white outline onClick={success.outline.action}>{ success.outline.label }</Button>
                                </> : null 
                            }
                        </FormContent>
                    </ContainerAuthenticated>
                </> : <>
                    <ContainerAuthenticated primary={step === 0} banner={step === 0 ? null : backgrounds?.find(fnd => fnd.locale === 'checkout')?.url }>  
                        <FormContent big>
                            {
                                step === 1 ? <>
                                    <FormSpacer /> 
                                    <FormText>Plano selecionado :</FormText> 
                                    <FormTouch onClick={back}>
                                        <SelectedPlan>
                                            <SelectedPlanTitle>{ formValue('plan')?.title }</SelectedPlanTitle>
                                            {
                                                formValue('plan')?.installment ? 
                                                <SelectedPlanText><b>{ parseCurrency(formValue('plan')?.value) }</b></SelectedPlanText>
                                                    :
                                                <SelectedPlanText><b>{ parseCurrency(formValue('plan')?.value) }</b></SelectedPlanText>
                                            }
                                        </SelectedPlan>
                                    </FormTouch>
                                </> : null
                            }

                            <FormTitle secondary={step === 0}>Passo { step+1 } de { steps.length }</FormTitle>
                            <FormText white={step === 0}>{ steps[step]?.title }</FormText> 

                            {
                                step === 1 ? <> 
                                        {/* <TabsContent>
                                            {
                                                paymentOptions.map((item, key) => 
                                                    <TabItem key={key} active={key == formValue('paymentMethod') } onClick={() => changeForm(key, 'paymentMethod')}>{ item.title }</TabItem>
                                                )
                                            }
                                        </TabsContent> */}

                                        {
                                            formValue('paymentMethod') == 0 ? <>
                                                <Input outline placeholder="Nome (como está escrito no cartão)" value={formValue('card_name')} onChange={e => changeForm(e.target.value, 'card_name')}  />
                                                <FormSpacer small /> 
                                                <Input outline mask={"9999 9999 9999 9999"} placeholder="Número do Cartão" value={formValue('card_number')} onChange={e => changeForm(e.target.value, 'card_number')}  />
                                                <FormSpacer small /> 
                                                <FormWrap>
                                                    <Input outline mask={"99/9999"} placeholder="Validade (MM/AAAA)" value={formValue('card_expires')} onChange={e => changeForm(e.target.value, 'card_expires')}  />
                                                    <Input outline mask={"999"} placeholder="Cod. Segurança" value={formValue('card_cvc')} onChange={e => changeForm(e.target.value, 'card_cvc')}  />
                                                </FormWrap>

                                                {/* <FormWrap>
                                                    <SelectInstallment outline placeholder="Parcelamento" value={formValue('card_installment')} onChange={e => changeForm(e.target.value, 'card_installment')} options={installmentOptions()} />
                                                </FormWrap> */}
                                                {/* <FormSpacer small />  */}
                                                {/* <Input outline placeholder="CPF ou CNPJ do Titular" value={formValue('card_document_user')} onChange={e => changeForm(e.target.value, 'card_document_user')}  /> */}

                                                <LicenseText>
                                                    Ao clicar no botão “Finalizar Compra” abaixo, você concorda com nossos <a>Termos de uso</a> e com nossa <a>Declaração de privacidade</a>, confirma ter mais de 18 anos e aceita que a <b>Join Kids renove automaticamente sua assinatura e cobre o preço da assinatura (atualmente {formValue('plan')?.renew}) da sua forma de pagamento até você cancelar. Você pode cancelar quando quiser para evitar cobranças futuras.</b> Para cancelar, acesse a página “Conta” e clique em “Cancelar assinatura”.
                                                </LicenseText>
                                            </> : null
                                        }
                                        
                                        {
                                            formValue('paymentMethod') == 1 ? <>
                                                <Input outline placeholder="CPF" value={formValue('document')} onChange={e => changeForm(e.target.value, 'document')}  />
                                                <LicenseText>
                                                    Faça o <b>download</b> do PDF ou Copie o <b>código de barra</b>, que aguardaremos a confirmação de pagamento. Por segurança enviamos o Boleto no seu e-mail.
                                                </LicenseText>
                                            </> : null
                                        }
                                        
                                </> : null
                            }

                            {
                                
                                step === 0 ? <>  
                                    {
                                        availablePlans.map((item, key) => 
                                            <PlanContent key={key}>
                                                <PlanImage image={item.image} />
                                                <Button { ...item.variant } loading={loading} onClick={() => selectPlan(item)} >{ item.action }</Button>
                                                <PlanText>{ item.text }</PlanText>
                                            </PlanContent>
                                        )
                                    }  
                                </> : <>
                                    <FormSpacer /> 
                                    <Button secondary={valid()} loading={loading} onClick={action}>{ (step+1) === steps.length ? 'Finalizar Compra' : 'Avançar' }</Button> 
                                </>
                            }

                            <FormSpacer /> 
                        </FormContent>
                    </ContainerAuthenticated> 
                </>
            }
        </>
    );
}