import React, { useContext, useEffect, useState } from "react";  

import {    
    DashboardSpacer,

    PageTitle,
    PageSubtitle,
    PageText,
    PageSection

} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";  

import { CoreContext } from "context/CoreContext";
import { Container, Row, Col } from "reactstrap";
import { ReadAbout } from "services/authentication";
import { Load } from "ui/styled";

import {marked} from 'marked';
import ContainerLandpage from "containers/Landpage";
import Footer from "components/Landpage/Footer";
import Banner from "components/Landpage/Banner";

export default function DashboardPrivacityPolice(){   

    const { currentCategory } = useContext(CoreContext)

    const [loading, setLoading] = useState(false)
    const [about, setAbout] = useState("")

    const init = async () => {
        setLoading(true)
        const result =  await ReadAbout()
 
        if(result?.about){

            marked.setOptions({
                gfm: true,
                tables: true,
                breaks: false,
                pedantic: false,
                sanitize: true,
                smartLists: true,
                smartypants: false
              });

            setAbout(marked.parse(result?.about))
        }
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
            <ContainerLandpage> 
                <Banner onlyHeader />  
                <PageSection>
                    <Container>
                        <Row>
                            <Col md={{ size: 10, offset:1 }}>
                                <DashboardSpacer />
                                <PageTitle>Termos de uso e Declaração de privacidade</PageTitle> 
                                {
                                    loading ? <Load /> :
                                    <PageText dangerouslySetInnerHTML={{ __html: about }} />
                                }
                            </Col>
                        </Row>
                    </Container>
                </PageSection>
                <Footer />
            </ContainerLandpage>
        </>
    );

}