import { parseCurrency, parseStrapiImage } from "utils"

const CryptoJS = require('crypto-js'); 
  
const profilesTitles = {
    male:'Menino',
    female:'Menina'
}

export const parseProfile = item => {
    return {
        ...item,
        id: item.id,
        name: item.name,
        avatar:{ image: parseStrapiImage(item?.picture?.url) },
        gender:{ title:profilesTitles[item.gender], type:item.gender }
    }
}

export const parseProfileImage = item => {
    return {
        ...item,
        id: item.id,
        name: item.name,
        image: parseStrapiImage(item.url) 
    }
}
 


const plans = {
    'Plano Básico':{
        type:'montly_plan',
        title:'JKids Mensal',
        image:'/background/background-5.png',
        action:`JKids Mensal - R$ 14,90 - Por Mês`,
        text:`Este plano é automaticamente renovado a cada mês`,
        renew:`R$14,90/mês`,
        variant:{ primary:true },
        value:14.9,
        installment: false
    },
    'Plano Família':{
        type:'yearly_plan',
        title:'JKids Anual',
        image:'/background/background-6.png',
        action:`JKids Anual - R$ 9,90 - Por Mês`,
        text:`Este plano é automaticamente renovado a cada 12 meses`,
        renew:`R$118,80/ano`,
        variant:{ secondary:true }, 
        value:118.8,
        installment: true
    }
}

export const parsePlan = (item, backgrounds) => ({
    ...plans[item.title],
    ...item,
    text: item.descriptor,
    action:`${item.title} - ${ parseCurrency(item.title === 'Anual' ? parseFloat(item.value / 12)  : item.value) } - Por Mês`,
    renew:`${ parseCurrency(item.value) }/${ item.title === 'Anual' ? 'ano' : 'mês' }`,
    image: parseStrapiImage(backgrounds?.find(fnd => fnd.locale === plans[item.title]?.type)?.url)
})











const categoriesOption = {
    'Filme':{
        title:'Filme',
        color:'blue'
    },
    'Série':{
        title:'Série',
        color:'yellow'
    },

    'Musical':{
        title:'Musical',
        color:'blue'
    },
    'Educativo':{
        title:'Educativo',
        color:'green'
    }
}

const CDN_ENDPOINT = 'https://vz-0ca6c7c3-50f.b-cdn.net'

const getBunnyUrl = (path) => {
//   console.log('getBunnyUrl', path) 
  const hashes = [ ...[...['c596d3c7', 'dab2'], ...['48fc', ...['93b4']], ...['15642c722816']]]
  const expires = Math.round(Date.now() / 1000) + 3600;
  const hashableBase = hashes.join('-') + path + expires; 

  let token = CryptoJS.SHA256(hashableBase).toString(CryptoJS.enc.Base64);
  token = token.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=/g, ''); 

  const url = `${CDN_ENDPOINT}/bcdn_token=${ token }&expires=${expires}&token_path=${path}`  
//   console.log('getBunnyUrl::url', url)
  return url
}

const parseBunnyImage = url => {
    if( `${ url }`.indexOf(CDN_ENDPOINT) !== -1 ){
        const path = `${ url }`.replace(CDN_ENDPOINT, '')
        return getBunnyUrl(path)
    } else {
        // console.log('skipping non bunny image', url)
    }
    return url
}


export const parseBanner = item => { 
    const bunny_image = parseBunnyImage(item?.featured_show?.data?.attributes?.bunny_image)
    return { 
        id: item?.featured_show?.data?.id,
        title: item?.featured_show?.data?.attributes?.name,
        image: bunny_image ? bunny_image : parseStrapiImage(item?.featured_banner?.data?.attributes?.url),
        category: categoriesOption['Série'] || {}
    }
}


const categoryIcons = {
    // 'Conteúdos': { icon:'smile' },
    // 'Educativos': { icon:'art' },
    // 'Filmes': { icon:'popcorn' }
}


export const parseCategory = item => { 
    return { 
        title: item?.attributes?.name, 
        id: item?.id, 
        icon: categoryIcons[item?.attributes?.name] || false
    }
}


const collectionResources = {
    'Meus Favoritos':{ icon:'heart', image: '/images/users/male-1.png' },
    'As 20 Músicas + Vistas':{ icon:'sound', image: '/images/users/male-2.png' },
    'Cante e Dance':{ icon:'dance', image: '/images/users/female-3.png' },
}

export const parseCollection = item =>  {
    return {
        id: item?.id, 
        title: item?.attributes?.name, 
        ...(collectionResources[item?.attributes?.name] || {}),
        videos: item?.attributes?.stream_shows?.data?.length ? item?.attributes?.stream_shows?.data?.map(parseVideo) : [],
        image: item?.attributes?.icon?.data?.attributes?.url ? parseStrapiImage(item?.attributes?.icon?.data?.attributes?.url) : null
    }
}

export const parseVideo = item => {
    const bunny_image = parseBunnyImage(item?.attributes?.bunny_image || item?.bunny_image)
    return { 
        id: item?.id, 
        title: item?.attributes?.name || item?.name, 
        description: item?.attributes?.description || item?.description, 
        cover: bunny_image ? bunny_image : parseStrapiImage(item?.attributes?.thumbnail?.data?.attributes?.url || item?.thumbnail?.data?.attributes?.url || item?.thumbnail?.url), 
        // category_id:1 
    }
}

export const parseVideoShow = item => {
    const bunny_image = parseBunnyImage(item?.attributes?.bunny_image || item?.bunny_image)
    return { 
        ...item,
        title:item.name,
        cover: bunny_image ? bunny_image : parseStrapiImage(item?.attributes?.wide_picture?.data?.attributes?.url || item?.wide_picture?.data?.attributes?.url || item?.wide_picture?.url), 
        thumb: bunny_image ? bunny_image : parseStrapiImage(item?.attributes?.thumbnail?.data?.attributes?.url || item?.thumbnail?.data?.attributes?.url || item?.thumbnail?.url), 
        // category_id:1 
    }
}
