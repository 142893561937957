import styled from 'styled-components' 

export const BannerImage = styled.div.attrs({ 
})`           

    position: relative;
    min-height: calc(100vh);
    width: 100%;

    background: var(--primary-color) url(/images/tv-background.png) no-repeat center center / cover;
`; 

export const BannerOut = styled.div.attrs({ 
})`            
    min-height: calc(100vh);
    height: 100%;
    width: 100%;

    background: var(--darkshadow-color); 
    mix-blend-mode: multiply;
    position: absolute;
    z-index:1;
`; 

const degradeeColor = "93,0,0"
// const degradeeColor = "249,94,20"

export const BannerContent = styled.div.attrs({ 
})`           
    min-height: calc(100vh);
    width: 100%; 

    background: -moz-linear-gradient(180deg, rgba(${ degradeeColor },.7) 0%, rgba(${ degradeeColor },.4) 0%, rgba(${ degradeeColor },.7) 110%);
    background: -webkit-linear-gradient(180deg, rgba(${ degradeeColor },.7) 0%, rgba(${ degradeeColor },.4) 0%, rgba(${ degradeeColor },.7) 110%);
    background: linear-gradient(180deg, rgba(${ degradeeColor },.7) 0%, rgba(${ degradeeColor },.4) 0%, rgba(${ degradeeColor },.7) 110%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(83,83,83,.3)",endColorstr="rgba(${ degradeeColor },.4)",GradientType=1);

    position:relative;
    z-index:2;
`; 

export const BannerCard = styled.div.attrs({ 
})`        
    // max-width: 920px;
    margin: 0 auto;
    padding: 40px 0 0;
`; 




export const BannerCenterContent = styled.div.attrs({ 
})`            
    display: flex;
    flex-direction: column;
    align-items: center;
`; 

export const BannerCenterActions = styled.div.attrs({ 
})`            
    margin: 40px auto 40px;
    flex:1;
    max-width: 250px;
`; 



export const BannerLogo = styled.img.attrs({ 
    src:'/icons/logo.svg'
})`           
    margin: 125px auto 20px;
`; 





export const BannerTitle = styled.h1.attrs({ 
})`       
    font-size: 42px;
    color: var(--white-color);
    font-weight: bold;
    margin: 0 0 30px;
`; 
export const BannerSubtitle = styled.div.attrs({ 
})`           
    font-size: 48px;
    color: var(--white-color);
    font-weight: bold;

    text-align: center; 
    margin: 20px auto;
    max-width: 340px;
`; 
export const BannerText = styled.div.attrs({ 
})`           
    font-size: 18px;
    color: var(--white-color);    

    text-align: center;
`; 
export const BannerSubtext = styled.div.attrs({ 
})`           
    font-size: 18px;
    color: var(--white-color);    
 
    margin: 10px auto 0;
    text-align: center;
    opacity: .7;
`; 



export const LoginContent = styled.div.attrs({ 
})`           
    margin: 0 0 0 auto;
    width: 160px;
    color: var(--white-color);   
`; 





export const ContentHeader = styled.div.attrs({ 
})`
    padding: 16px;
    background: var(--darkprimary-color); 
    display: flex;
    justify-content: center;
`; 

export const ContentHeaderIcon = styled.div.attrs({ 
})`
    width: 164px;
    height: 48px;
    background: url(/images/logo-hoxx.png) no-repeat center center / contain;
`; 












export const ContentCardsPlan = styled.div.attrs({ 
})`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
`; 
export const CardPlan = styled.div.attrs({ 
})`
    max-width: 440px;
    width: 100%;
    background: var(--darkshadow-color); 
    padding: 24px 32px;
    border-radius: 16px;
    position: relative;
`; 
export const CardPlanTitle = styled.div.attrs({ 
})`
    font-size: 36px;
    color: var(--white-color); 
`; 
export const CardPlanPrice = styled.div.attrs({ 
})`
    font-size: 28px;
    color: var(--lg-color); 
    margin: 10px 0 40px;
`; 
export const CardPlanBenefitRow = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
`; 
export const CardPlanBenefitIcon = styled.img.attrs({ 
    src:"/images/check.png",
    width: 24
})`
`; 
export const CardPlanBenefitText = styled.div.attrs({ 
})`
    font-size: 16px;
    color: var(--white-color); 
    opacity: .8;
`; 

export const CardPlanLabel = styled.div.attrs({ 
})`
    position: absolute;
    padding: 8px 12px;
    background: var(--primary-color); 
    color: var(--white-color); 
    border-radius: 10px;
    top: -20px;
    left: 140px;
`; 






export const ContentNews = styled.div.attrs({ 
})`
    padding: 60px 0;
`; 
export const CardNews = styled.div.attrs({ 
})`
    border-radius: 16px;
    overflow: hidden;
    display: flex;
`; 
export const CardNewsInfos = styled.div.attrs({ 
})`
    max-width: 450px;
    background: var(--darkshadow-color); 
    padding: 24px 32px;
`; 
export const CardNewsInfosTitle = styled.div.attrs({ 
})`
    font-size: 36px;
    font-weight: bold;
    color: var(--white-color); 
    margin: 0 0 10px;
`; 
export const CardNewsInfosText = styled.div.attrs({ 
})`
    font-size: 18px;
    color: var(--white-color);  
    opacity: .7;
`; 
export const CardNewsImage = styled.div.attrs({ 
})`
    width: 100%;
    background: var(--shadow-color) url(/images/news-1.png) no-repeat center center / cover;  
    min-width: 40px;
    mix-blend-mode: revert;
    opacity: .8;
`; 